import { Injectable, signal } from '@angular/core';
import { getStorage, Storage, ref, uploadBytesResumable, getDownloadURL, uploadBytes } from '@angular/fire/storage';
import { Firestore, addDoc, collection, updateDoc, getDoc, doc, Timestamp } from '@angular/fire/firestore';
import { BehaviorSubject, Observable } from 'rxjs';
import { UserService } from './user.service';
import { DeviceDetectorService } from 'ngx-device-detector';
import { RecordingTimerService } from './recording-timer.service';

@Injectable({
  providedIn: 'root'
})
export class RecordingService {
  private mediaRecorder: MediaRecorder | null = null;
  private recordedChunks: Blob[] = [];
  private recordingStartTime: number = 0;
  private recordingDuration: number = 0;
  private chunkCounter: number = 0;
  private editCounter: number = 0; // Add this line to define the editCounter property

  private isRecordingSubject = new BehaviorSubject<boolean>(false);
  isRecording$: Observable<boolean> = this.isRecordingSubject.asObservable();

  private recordingProgressSubject = new BehaviorSubject<number>(0);
  recordingProgress$: Observable<number> = this.recordingProgressSubject.asObservable();

  private recordedBlobSubject = new BehaviorSubject<Blob | null>(null);
  recordedBlob$: Observable<Blob | null> = this.recordedBlobSubject.asObservable();

  private recordingStatusSubject = new BehaviorSubject<'inactive' | 'recording' | 'paused'>('inactive');
  recordingStatus$: Observable<'inactive' | 'recording' | 'paused'> = this.recordingStatusSubject.asObservable();

  private uploadProgressSignal = signal(0);

  constructor(
    private storage: Storage,
    private firestore: Firestore,
    private userService: UserService,
    private deviceDetectorService: DeviceDetectorService,
    private recordingTimerService: RecordingTimerService
  ) {
    this.editCounter = 0; // Initialize editCounter in the constructor
  }

  getRecordedBlob(): Observable<Blob | null> {
    return this.recordedBlob$;
  }

  async saveEditedRecording(blobData: { blob: Blob, fileName: string }, userId: string): Promise<{ success: boolean, data?: { filename: string, downloadURL: string } }> {
    if (!blobData.blob) {
      console.error('No blob provided for edited recording');
      return { success: false };
    }

    try {
      const storage = getStorage();
      const storageRef = ref(storage, `recordings/webcam/${userId}/${blobData.fileName}`);

      console.log('Uploading edited video to:', `recordings/webcam/${userId}/${blobData.fileName}`);
      const snapshot = await uploadBytes(storageRef, blobData.blob);
      const downloadURL = await getDownloadURL(snapshot.ref);

      console.log('Edited video uploaded successfully:', blobData.fileName);
      console.log('Download URL:', downloadURL);
      return { success: true, data: { filename: blobData.fileName, downloadURL } };
    } catch (error) {
      console.error('Error saving edited recording:', error);
      return { success: false };
    }
  }

  async startRecording(stream: MediaStream): Promise<void> {
    console.group('RecordingService: startRecording');
    console.log('Starting recording with stream:', stream);
    
    this.resetRecording();
  
    try {
      const options = this.getSupportedMediaRecorderOptions();
      this.mediaRecorder = new MediaRecorder(stream, options);
      
      this.setupMediaRecorderEventListeners();
      
      this.mediaRecorder.start(1000); // Capture in 1-second chunks
      console.log('MediaRecorder started with 1-second intervals');
  
      console.log('RecordingService: Starting timer');
      this.recordingTimerService.startTimer(180);
    } catch (e) {
      console.error('Failed to create MediaRecorder:', e);
      throw e;
    } finally {
      console.groupEnd();
    }
  }

  private getEditedFilename(originalFilename: string): string {
    const baseFilename = originalFilename.split('.')[0];
    const extension = originalFilename.split('.').pop();
    const editNumber = this.getNextEditNumber(baseFilename);
    return `${baseFilename}_edited_${editNumber.toString().padStart(3, '0')}.${extension}`;
  }

  private getNextEditNumber(baseFilename: string): number {
    // In a real-world scenario, you'd need to check existing files
    // in the storage bucket to determine the next edit number.
    // For now, we'll use a simple incrementing number.
    this.editCounter = this.editCounter + 1;
    return this.editCounter;
  }

  private getSupportedMediaRecorderOptions(): MediaRecorderOptions {
    const mimeTypes = [
      'video/mp4;codecs=h264,aac',
      'video/mp4'
    ];

    for (const mimeType of mimeTypes) {
      if (MediaRecorder.isTypeSupported(mimeType)) {
        console.log(`Using MIME type: ${mimeType}`);
        return { mimeType };
      }
    }

    console.warn('No supported MIME type found for MP4. Using default options.');
    return {};
  }

  private setupMediaRecorderEventListeners(): void {
    if (!this.mediaRecorder) {
      throw new Error('MediaRecorder is not initialized');
    }

    this.mediaRecorder.ondataavailable = (event: BlobEvent) => {
      if (event.data && event.data.size > 0) {
        this.chunkCounter++;
        console.log(`Received data chunk #${this.chunkCounter}, size: ${event.data.size} bytes`);
        this.recordedChunks.push(event.data);
      } else {
        console.warn(`Received empty data chunk #${this.chunkCounter}`);
      }
    };

    this.mediaRecorder.onstart = () => {
      this.recordingStartTime = Date.now();
      this.isRecordingSubject.next(true);
      this.recordingStatusSubject.next('recording');
      console.log('Recording started at:', new Date(this.recordingStartTime).toISOString());
    };

    this.mediaRecorder.onstop = () => {
      const endTime = Date.now();
      this.recordingDuration = endTime - this.recordingStartTime;
      console.log(`Recording stopped at: ${new Date(endTime).toISOString()}, Duration: ${this.recordingDuration}ms`);
      this.isRecordingSubject.next(false);
      this.recordingProgressSubject.next(100);
      this.recordingStatusSubject.next('inactive');
      this.createFixedDurationBlob().then(blob => {
        this.recordedBlobSubject.next(blob);
        console.log(`Final blob created, size: ${blob.size} bytes`);
      });

      // Stop the timer
      this.recordingTimerService.stopTimer();
    };

    this.mediaRecorder.onpause = () => {
      this.recordingStatusSubject.next('paused');
      console.log('Recording paused');
    };

    this.mediaRecorder.onresume = () => {
      this.recordingStatusSubject.next('recording');
      console.log('Recording resumed');
    };
  }

  async stopRecording(): Promise<Blob | null> {
    console.group('RecordingService: stopRecording');
    console.log('Stopping recording');
    if (this.mediaRecorder && this.mediaRecorder.state !== 'inactive') {
      return new Promise<Blob>((resolve) => {
        this.mediaRecorder!.onstop = () => {
          const recordedBlob = new Blob(this.recordedChunks, { type: 'video/mp4' });
          this.recordedChunks = [];
          this.isRecordingSubject.next(false);
          console.log('Recording stopped, blob created:', recordedBlob);
          console.groupEnd();
          resolve(recordedBlob);
        };
        this.mediaRecorder!.stop();
      });
    } else {
      console.log('No active recording to stop');
      console.groupEnd();
      return null;
    }
  }

  pauseRecording(): void {
    if (this.mediaRecorder && this.mediaRecorder.state === 'recording') {
      this.mediaRecorder.pause();
    }
  }

  resumeRecording(): void {
    if (this.mediaRecorder && this.mediaRecorder.state === 'paused') {
      this.mediaRecorder.resume();
    }
  }

  clearRecording(): void {
    this.recordedChunks = [];
    this.recordedBlobSubject.next(null);
    this.recordingProgressSubject.next(0);
    this.recordingDuration = 0;
    
    // Add this line to revoke the blob URL
    if (this.recordedBlobSubject.value) {
      URL.revokeObjectURL(URL.createObjectURL(this.recordedBlobSubject.value));
    }
    
    console.log('Recording cleared, blob URL revoked');
  }

  getRecordingDuration(): number {
    return this.recordingDuration;
  }

  private async createFixedDurationBlob(): Promise<Blob> {
    console.log('Creating fixed duration blob');
    console.log(`Number of chunks: ${this.recordedChunks.length}`);
    const blob = new Blob(this.recordedChunks, { type: 'video/mp4' });
    console.log(`Final blob size: ${blob.size} bytes`);
    return blob;
  }

  private getTotalSize(): number {
    return this.recordedChunks.reduce((total, chunk) => total + chunk.size, 0);
  }

  async saveRecording(userId?: string, type?: string, blob?: Blob): Promise<{ success: boolean, data?: { filename: string, downloadURL: string } }> {
    return new Promise((resolve, reject) => {
      if (blob || this.recordedChunks.length) {
        const recordingBlob = blob || new Blob(this.recordedChunks, { type: 'video/mp4' });
        
        if (userId && type) {
          const filename = `recordings/${type}/${userId}/${Date.now()}.mp4`;
          const storageRef = ref(this.storage, filename);
          const uploadTask = uploadBytesResumable(storageRef, recordingBlob);

          uploadTask.on('state_changed',
            (snapshot) => {
              const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
              this.uploadProgressSignal.set(isNaN(progress) ? 0 : progress);
              console.log('Upload progress:', this.uploadProgressSignal());
            },
            (error) => {
              console.error('Upload error:', error);
              this.uploadProgressSignal.set(0);
              resolve({ success: false });
            },
            async () => {
              try {
                const downloadURL = await getDownloadURL(storageRef);
                const docRef = await this.saveRecordingMetadata(filename, downloadURL, userId, type);
                this.uploadProgressSignal.set(100);
                console.log('Recording saved successfully:', { filename, downloadURL });
                setTimeout(() => {
                  resolve({ success: true, data: { filename, downloadURL } });
                }, 2000); // Delay resolution by 2 seconds
              } catch (error) {
                console.error('Error saving recording metadata:', error);
                this.uploadProgressSignal.set(0);
                resolve({ success: false });
              }
            }
          );
        } else {
          resolve({ success: true, data: { filename: 'local', downloadURL: URL.createObjectURL(recordingBlob) } });
        }
      } else {
        this.uploadProgressSignal.set(0);
        resolve({ success: false });
      }
    });
  }

  getUploadProgress() {
    return this.uploadProgressSignal;
  }

  private async saveRecordingMetadata(filename: string, downloadURL: string, userId: string, type: string) {
    const recordingData = {
      filename,
      downloadURL,
      userId,
      type,
      createdAt: new Date(),
      duration: this.recordingDuration
    };

    const docRef = await addDoc(collection(this.firestore, 'recordings'), recordingData);
    await updateDoc(docRef, { id: docRef.id });
    console.log('New recording profile added:', { id: docRef.id, ...recordingData });
    return docRef;
  }

  async getVideoData(docId: string): Promise<{ filename: string, downloadURL: string }> {
    try {
      const docRef = doc(this.firestore, 'recordings', docId);
      const docSnap = await getDoc(docRef);

      if (docSnap.exists()) {
        const data = docSnap.data();
        return {
          filename: data['filename'],
          downloadURL: data['downloadURL']
        };
      } else {
        throw new Error('No such document!');
      }
    } catch (error) {
      console.error('Error fetching video data:', error);
      throw error;
    }
  }

  async handleStreamSuccess(
    stream: MediaStream,
    videoElement: HTMLVideoElement,
    onSuccess: () => void,
    onError: (error: any) => void
  ): Promise<void> {
    console.log('Handling stream success');
    videoElement.srcObject = stream;
    
    try {
      await videoElement.play();
      console.log('Video playback started');
      
      videoElement.classList.forEach(className => {
        if (className.startsWith('blur-')) {
          videoElement.classList.remove(className);
        }
      });

      // Mute audio output
      videoElement.muted = true;

      onSuccess();
    } catch (error) {
      console.error('Error playing video:', error);
      onError(error);
    }
  }

  resetRecording(): void {
    console.log('Resetting recording state');
    this.mediaRecorder = null;
    this.recordedChunks = [];
    this.recordingStartTime = 0;
    this.recordingDuration = 0;
    this.chunkCounter = 0;
    this.isRecordingSubject.next(false);
    this.recordingProgressSubject.next(0);
    this.recordedBlobSubject.next(null);
    this.recordingStatusSubject.next('inactive');
    this.uploadProgressSignal.set(0);

    // Reset the timer
    this.recordingTimerService.resetTimer();
  }
}