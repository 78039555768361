import { Injectable } from '@angular/core';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { BehaviorSubject } from 'rxjs';
import { getFirestoreInstance } from '../firebase-config';

@Injectable({
    providedIn: 'root'
})
export class AppConfigService {
    private configSubject = new BehaviorSubject<any>(null);
    config$ = this.configSubject.asObservable();

    constructor() { }

    async loadConfig(): Promise<void> {
        const firestore = getFirestoreInstance();
        const configRef = doc(firestore, 'config', 'root');
        const configSnap = await getDoc(configRef);
        if (configSnap.exists()) {
            this.configSubject.next(configSnap.data());
        } else {
            // If the document doesn't exist, create it with default values
            const defaultConfig = { erudaDebug: false };
            await setDoc(configRef, defaultConfig);
            this.configSubject.next(defaultConfig);
        }
    }

    get erudaDebug(): boolean {
        return this.configSubject.value?.erudaDebug || false;
    }
}