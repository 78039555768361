<section class="mt-0 pt-0 my-4 p-4 sm:my-8 sm:p-8 lg:my-12 lg:p-12 max-w-full lg:max-w-screen-xl mx-auto">

    <h2 class="text-2xl font-bold mb-6 text-secondary">Audio Settings</h2>

    <!-- Add this section above the app-sound-profile-visualiser -->
    <div class="mb-4">
        <button class="btn btn-primary mr-2" (click)="testAgainstProfiles()" [disabled]="isRecording() || isTesting()">
            {{ isTesting() ? 'Testing...' : 'Test against profiles' }}
        </button>
        <button class="btn btn-secondary" (click)="exportAllProfiles()" [disabled]="isExporting()">
            {{ isExporting() ? 'Exporting...' : 'Export All Profiles' }}
        </button>
    </div>

    <div *ngIf="isTesting()" class="card w-96 bg-base-100 shadow-xl mb-4">
        <div class="card-body">
            <h2 class="card-title">Testing against profiles</h2>
            <progress class="progress progress-primary w-56" [value]="testProgress()" max="100"></progress>

        </div>
    </div>

    <app-sound-profile-visualiser [profiles]="getAllProfiles()"></app-sound-profile-visualiser>

    <!-- Recording progress bar -->
    <div *ngIf="isRecording()"
        class="fixed top-0 left-0 right-0 z-50 p-4 bg-info text-info-content flex items-center justify-center">
        <div class="text-center mb-2">Recording in progress...</div>
        <div class="w-full bg-gray-200 rounded-full h-2.5 dark:bg-gray-700">
            <div class="bg-blue-600 h-2.5 rounded-full transition-all duration-50 ease-linear"
                [style.width]="recordingProgress() + '%'"></div>
        </div>
    </div>

    <!-- Uploading status -->
    <div *ngIf="isUploading()"
        class="fixed top-0 left-0 right-0 z-50 p-4 bg-info text-info-content flex items-center justify-center">
        <svg class="animate-spin h-5 w-5 mr-3" viewBox="0 0 24 24">
            <circle class="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" stroke-width="4"></circle>
            <path class="opacity-75" fill="currentColor"
                d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z">
            </path>
        </svg>
        Uploading audio patterns... {{ uploadProgress() | number:'1.0-0' }}%
    </div>

    <!-- Positive Breath Pattern Section -->
    <div class="card bg-base-100 lg:shadow-xl mb-8 px-1 lg:px-4">
        <div class="card-body px-1 lg:px-4">
            <h3 class="card-title text-lg font-semibold">Positive Blow Pattern Calibration:</h3>
            <p class="mb-4">Create a frequency domain profile of blow audio that will be used for FFT-based pattern
                matching.</p>

            <div class="grid grid-cols-1 gap-6 mb-8">
                <!-- Breath Pattern Card -->
                <div class="card bg-base-100">
                    <div class="card-body px-1 lg:px-4">
                        <h4 class="card-title text-base">Blow Pattern</h4>
                        <button class="btn btn-primary w-fit relative" (click)="startRecording('blow')"
                            [disabled]="isRecording() || isTesting() || breathProfiles().length >= MAX_PROFILES_PER_TYPE">
                            💨 Record Blow
                            <span *ngIf="isRecording() && !isTesting() && currentRecordingType() === 'blow'"
                                class="absolute -top-1 -right-1 flex h-3 w-3">
                                <span
                                    class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                            </span>
                        </button>
                        <div class="overflow-x-auto">
                            <table class="table w-full">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Created</th>
                                        <th>Created By</th>
                                        <th>Device Type</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let profile of breathProfiles(); trackBy: trackByFn">
                                        <td>{{ profile.id }}</td>
                                        <td>{{ profile.createdAt | timestamp | date:'short' }}</td>
                                        <td>{{ getUserDisplayName(profile.createdBy) }}</td>
                                        <td>
                                            <select class="select select-bordered w-full max-w-xs"
                                                [(ngModel)]="profile.deviceType" (change)="updateDeviceType(profile)">
                                                <option [ngValue]="null">Not specified</option>
                                                <option *ngFor="let type of deviceTypes" [value]="type">{{ type }}
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="btn btn-circle btn-sm" (click)="playAudio(profile)"
                                                [disabled]="isPlaying() === profile.id">
                                                <ng-container *ngIf="isPlaying() !== profile.id; else pauseIcon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                                        viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </ng-container>
                                                <ng-template #pauseIcon>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                                        viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </ng-template>
                                            </button>
                                            <button class="btn btn-sm btn-ghost"
                                                (click)="confirmDelete(profile)">🗑️</button>
                                            <button class="btn btn-sm btn-ghost"
                                                (click)="showProfileDetails(profile)">👁️</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- Negative Pattern Calibration Section -->
    <div class="card bg-base-100 lg:shadow-xl mb-8 px-1 lg:px-4">
        <div class="card-body px-1 lg:px-4">
            <h3 class="card-title text-lg font-semibold">Negative Pattern Calibration:</h3>
            <p class="mb-4">Generate FFT profiles for non-breath audio to improve detection accuracy and reduce false
                positives.</p>

            <div class="grid grid-cols-1 gap-6">
                <!-- Laugh Pattern Card -->
                <div class="card">
                    <div class="card-body px-1">
                        <h4 class="card-title text-base">Laugh Pattern</h4>
                        <button class="btn btn-primary w-fit relative" (click)="startRecording('laugh')"
                            [disabled]="isRecording() || laughProfiles().length >= MAX_PROFILES_PER_TYPE">
                            😄 Record Laugh
                            <span *ngIf="isRecording() && currentRecordingType() === 'laugh'"
                                class="absolute -top-1 -right-1 flex h-3 w-3">
                                <span
                                    class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                            </span>
                        </button>
                        <div class="overflow-x-auto">
                            <table class="table w-full">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Created</th>
                                        <th>Created By</th>
                                        <th>Device Type</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let profile of laughProfiles(); trackBy: trackByFn">
                                        <td>{{ profile.id }}</td>
                                        <td>{{ profile.createdAt | timestamp | date:'short' }}</td>
                                        <td>{{ getUserDisplayName(profile.createdBy) }}</td>
                                        <td>
                                            <select [(ngModel)]="profile.deviceType"
                                                (change)="updateDeviceType(profile)"
                                                class="select select-bordered w-full max-w-xs">
                                                <option [ngValue]="null">Not specified</option>
                                                <option *ngFor="let type of deviceTypes" [value]="type">{{ type }}
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="btn btn-circle btn-sm" (click)="playAudio(profile)"
                                                [disabled]="isPlaying() === profile.id">
                                                <ng-container *ngIf="isPlaying() !== profile.id; else pauseIcon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                                        viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </ng-container>
                                                <ng-template #pauseIcon>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                                        viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </ng-template>
                                            </button>
                                            <button class="btn btn-sm btn-ghost"
                                                (click)="confirmDelete(profile)">🗑️</button>
                                            <button class="btn btn-sm btn-ghost"
                                                (click)="showProfileDetails(profile)">👁️</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- Clap Pattern Card -->
                <div class="card">
                    <div class="card-body px-1">
                        <h4 class="card-title text-base">Clap Pattern</h4>
                        <button class="btn btn-primary w-fit relative" (click)="startRecording('clap')"
                            [disabled]="isRecording() || clapProfiles().length >= MAX_PROFILES_PER_TYPE">
                            👏 Record Clap
                            <span *ngIf="isRecording() && currentRecordingType() === 'clap'"
                                class="absolute -top-1 -right-1 flex h-3 w-3">
                                <span
                                    class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                            </span>
                        </button>
                        <div class="overflow-x-auto">
                            <table class="table w-full">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Created</th>
                                        <th>Created By</th>
                                        <th>Device Type</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let profile of clapProfiles(); trackBy: trackByFn">
                                        <td>{{ profile.id }}</td>
                                        <td>{{ profile.createdAt | timestamp | date:'short' }}</td>
                                        <td>{{ getUserDisplayName(profile.createdBy) }}</td>
                                        <td>
                                            <select class="select select-bordered w-full max-w-xs"
                                                [(ngModel)]="profile.deviceType" (change)="updateDeviceType(profile)">
                                                <option [ngValue]="null">Not specified</option>
                                                <option *ngFor="let type of deviceTypes" [value]="type">{{ type }}
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="btn btn-circle btn-sm" (click)="playAudio(profile)"
                                                [disabled]="isPlaying() === profile.id">
                                                <ng-container *ngIf="isPlaying() !== profile.id; else pauseIcon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                                        viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </ng-container>
                                                <ng-template #pauseIcon>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                                        viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </ng-template>
                                            </button>
                                            <button class="btn btn-sm btn-ghost"
                                                (click)="confirmDelete(profile)">🗑️</button>
                                            <button class="btn btn-sm btn-ghost"
                                                (click)="showProfileDetails(profile)">👁️</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

                <!-- Hello Pattern Card -->
                <div class="card">
                    <div class="card-body px-1">
                        <h4 class="card-title text-base">Hello Pattern</h4>
                        <button class="btn btn-primary w-fit relative" (click)="startRecording('hello')"
                            [disabled]="isRecording() || helloProfiles().length >= MAX_PROFILES_PER_TYPE">
                            👋 Record Hello
                            <span *ngIf="isRecording() && currentRecordingType() === 'hello'"
                                class="absolute -top-1 -right-1 flex h-3 w-3">
                                <span
                                    class="animate-ping absolute inline-flex h-full w-full rounded-full bg-red-400 opacity-75"></span>
                                <span class="relative inline-flex rounded-full h-3 w-3 bg-red-500"></span>
                            </span>
                        </button>
                        <div class="overflow-x-auto">
                            <table class="table w-full">
                                <thead>
                                    <tr>
                                        <th>ID</th>
                                        <th>Created</th>
                                        <th>Created By</th>
                                        <th>Device Type</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr *ngFor="let profile of helloProfiles(); trackBy: trackByFn">
                                        <td>{{ profile.id }}</td>
                                        <td>{{ profile.createdAt | timestamp | date:'short' }}</td>
                                        <td>{{ getUserDisplayName(profile.createdBy) }}</td>
                                        <td>
                                            <select class="select select-bordered w-full max-w-xs"
                                                [(ngModel)]="profile.deviceType" (change)="updateDeviceType(profile)">
                                                <option [ngValue]="null">Not specified</option>
                                                <option *ngFor="let type of deviceTypes" [value]="type">{{ type }}
                                                </option>
                                            </select>
                                        </td>
                                        <td>
                                            <button class="btn btn-circle btn-sm" (click)="playAudio(profile)"
                                                [disabled]="isPlaying() === profile.id">
                                                <ng-container *ngIf="isPlaying() !== profile.id; else pauseIcon">
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                                        viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M14.752 11.168l-3.197-2.132A1 1 0 0010 9.87v4.263a1 1 0 001.555.832l3.197-2.132a1 1 0 000-1.664z" />
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2" d="M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </ng-container>
                                                <ng-template #pauseIcon>
                                                    <svg xmlns="http://www.w3.org/2000/svg" class="h-6 w-6" fill="none"
                                                        viewBox="0 0 24 24" stroke="currentColor">
                                                        <path stroke-linecap="round" stroke-linejoin="round"
                                                            stroke-width="2"
                                                            d="M10 9v6m4-6v6m7-3a9 9 0 11-18 0 9 9 0 0118 0z" />
                                                    </svg>
                                                </ng-template>
                                            </button>
                                            <button class="btn btn-sm btn-ghost"
                                                (click)="confirmDelete(profile)">🗑️</button>
                                            <button class="btn btn-sm btn-ghost"
                                                (click)="showProfileDetails(profile)">👁️</button>
                                        </td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>

    <!-- Add this at the end of your component template -->
    <dialog id="profile_details_modal" class="modal">
        <div class="modal-box">
            <div class="modal-action">
                <form method="dialog">
                    <button class="btn">Close</button>
                </form>
            </div>
            <h3 class="font-bold text-lg mb-4">Profile Details</h3>
            @if (selectedProfile) {
            <div class="overflow-x-auto">
                <table class="table w-full">
                    <thead>
                        <tr>
                            <th>Property</th>
                            <th>Value</th>
                        </tr>
                    </thead>
                    <tbody>
                        @for (entry of selectedProfileEntries; track entry.key) {
                        <tr>
                            <td>{{ entry.key }}</td>
                            <td>
                                @if (isArray(entry.value)) {
                                <div class="flex items-center">
                                    <span class="mr-2">{{ formatValue(entry.value, entry.key) }}</span>
                                    <button class="btn btn-xs" (click)="toggleArrayExpansion(entry.key)">
                                        {{ expandedArrays[entry.key] ? 'Collapse' : 'Expand' }} Data
                                    </button>
                                </div>
                                } @else {
                                {{ formatValue(entry.value, entry.key) }}
                                }
                            </td>
                        </tr>
                        }
                    </tbody>
                </table>
            </div>
            }

        </div>
    </dialog>

</section>
