import { Component, Input, Output, EventEmitter, OnChanges, OnInit, SimpleChanges, AfterViewInit, ViewChild, ElementRef, ChangeDetectorRef, HostListener, OnDestroy } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { VideoTrimService } from '@services/video-trim.service';
import { RecordingService } from '@services/recording.service';
import { DeleteRunwayService } from '@services/delete-runway.service';
import { StorageService } from '@services/storage.service';
import { UserService } from '@services/user.service';
import { SwUpdate } from '@angular/service-worker';
import { FfmpegWorkerService } from '../../services/ffmpeg-worker.service';
import { Subscription } from 'rxjs';

const DEBUG_MODE = false;

@Component({
  selector: 'app-videoedit',
  standalone: true,
  imports: [CommonModule, ReactiveFormsModule, FormsModule],
  templateUrl: './videoedit.component.html'
})
export class VideoEditComponent implements OnChanges, OnInit, AfterViewInit, OnDestroy {
  @Input() videoDuration: number = 0;
  @Input() currentTime: number = 0;
  @Input() videoUrl: string | null = null;
  @Input() originalFilename: string = '';
  @Input() readOnly: boolean = false;
  @Output() croppedVideoSaved = new EventEmitter<{ start: number, end: number, url: string, duration: number }>();
  @Output() seekTo = new EventEmitter<number>();
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('fileInput') fileInput!: ElementRef<HTMLInputElement>;

  @Output() videoMetadataLoaded = new EventEmitter<void>();
  @Output() videoTimeUpdate = new EventEmitter<void>();

  timeForm: FormGroup;
  isVideoLoaded: boolean = false;
  trimmedVideoUrl: string | null = null;
  showTrimmedAlert: boolean = false;
  trimmedDuration: number | null = null;

  loaded = false;
  startTime = 0;
  endTime = 5;

  selectedFile: File | null = null;
  gifUrl: string | null = null;
  workerReady: boolean = false;
  ffmpegStatus: string = 'Loading FFmpeg...';
  private subscription: Subscription | null = null;

  dragging: 'start' | 'end' | 'current' | null = null;
  sliderRect: DOMRect | null = null;

  isPlaying: boolean = false;
  currentTimePosition: number = 0;

  videoFile: File | null = null;

  ffmpegVersion: string | null = null;
  ffmpegLoading = true;
  ffmpegLoadProgress = 0;
  ffmpegError: string | null = null;

  downloadURL: string | null = null;

  showTrimControls = false;
  showInfoAlert = true;

  precisionTrim: boolean = false; // Added default value

  @Output() videoTrimmed = new EventEmitter<{ url: string, filename: string }>();

  // New state variable for Trim button
  trimButtonState: 'idle' | 'loading' | 'performing' = 'idle';

  // **New Variables for Original Start and End Times**
  originalStartTime: number = 0;
  originalEndTime: number = 0;

  // **New Variables for Trim Button Enablement and Tooltip**
  showTooltip: boolean = false; // To control tooltip visibility

  // Add this new property
  savingVideo: boolean = false;

  private worker: Worker | null = null;

  constructor(
    private fb: FormBuilder,
    private cd: ChangeDetectorRef,
    private videoTrimService: VideoTrimService,
    private recordingService: RecordingService,
    private deleteRunwayService: DeleteRunwayService,
    private storageService: StorageService,
    private userService: UserService,
    private swUpdate: SwUpdate,
    private ffmpegWorkerService: FfmpegWorkerService
  ) {
    this.timeForm = this.fb.group({
      startTime: ['00:00', [Validators.required, Validators.pattern(/^([0-5][0-9]):([0-5][0-9])$/)]],
      endTime: ['00:00', [Validators.required, Validators.pattern(/^([0-5][0-9]):([0-5][0-9])$/)]]
    });
    this.initializeWorker();
  }

  ngOnInit() {
    this.log('ngOnInit called');
    if (this.videoUrl) {
      this.processVideo();
    }
    this.endTime = this.videoDuration;
    this.originalEndTime = this.videoDuration; // **Initialize Original End Time**
    this.originalStartTime = this.startTime; // **Initialize Original Start Time**
    this.updateTimeForm();

    this.videoTrimService.getLoaded().subscribe(loaded => {
      this.loaded = loaded;
      this.cd.detectChanges();
    });

    this.setupWorkerSubscriptions();
  }

  ngAfterViewInit() {
    if (this.videoElement && this.videoElement.nativeElement) {
      console.log('Video element found:', this.videoElement.nativeElement);
    } else {
      console.error('Video element not found in the template');
    }
  }

  ngOnChanges(changes: SimpleChanges) {
    this.logGroup('VideoEditComponent: ngOnChanges');
    this.log('Changes:', changes);

    if (changes['videoDuration']) {
      this.log('Video duration changed:', this.videoDuration);
      this.updateEndTime();
    }
    if (changes['videoUrl']) {
      this.log('Video URL changed:', this.videoUrl);
      if (this.videoUrl) {
        this.processVideo();
      }
    }
    this.logVideoState();
    this.logGroupEnd();
  }

  ngOnDestroy() {
    if (this.worker) {
      this.worker.terminate();
    }
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }

  private log(...args: any[]): void {
    if (DEBUG_MODE) {
      console.log(...args);
    }
  }

  private logGroup(label: string): void {
    if (DEBUG_MODE) {
      console.group(label);
    }
  }

  private logGroupEnd(): void {
    if (DEBUG_MODE) {
      console.groupEnd();
    }
  }

  setupVideoElement() {
    this.logGroup('VideoEditComponent: setupVideoElement');
    if (this.videoElement && this.videoElement.nativeElement) {
      const video = this.videoElement.nativeElement;
      this.log('Video Element Ready State:', video.readyState);
      this.log('Video Element Source:', video.src);
      this.log('Video Element Duration:', video.duration);
      video.addEventListener('timeupdate', this.onTimeUpdate.bind(this));

      if (video.readyState >= 2) {
        this.onVideoLoaded();
      } else {
        video.onloadeddata = () => this.onVideoLoaded();
      }
    } else {
      this.log('Video Element: Not Available');
    }
    this.logGroupEnd();
  }

  private processVideo() {
    this.logGroup('VideoEditComponent: processVideo');
    if (!this.videoUrl) {
      this.log('No video URL provided');
      this.isVideoLoaded = false;
      this.cd.detectChanges();
      this.logGroupEnd();
      return;
    }

    this.isVideoLoaded = false;
    this.cd.detectChanges();

    if (this.videoElement && this.videoElement.nativeElement) {
      this.videoElement.nativeElement.src = this.videoUrl;
      this.setupVideoElement();
    }
    this.logGroupEnd();
  }

  private checkVideoLoaded() {
    this.logGroup('VideoEditComponent: checkVideoLoaded');
    if (this.videoElement && this.videoElement.nativeElement) {
      const video = this.videoElement.nativeElement;
      this.log('Video element readyState:', video.readyState);
      this.log('Video element src:', video.src);

      if (video.readyState >= 2) {
        this.onVideoLoaded();
      } else {
        video.onloadeddata = () => this.onVideoLoaded();
      }
    } else {
      this.log('Video element not found');
    }
    this.logGroupEnd();
  }

  private onVideoLoaded() {
    this.logGroup('VideoEditComponent: onVideoLoaded');
    this.log('Video loaded');
    this.isVideoLoaded = true;
    this.cd.detectChanges();
    this.logVideoState();
    this.logGroupEnd();
  }

  onVideoMetadataLoaded(event: Event): void {
    if (this.videoElement && this.videoElement.nativeElement) {
      this.videoDuration = this.videoElement.nativeElement.duration;
      console.log('Video duration set:', this.videoDuration);
      this.endTime = this.videoDuration;
      this.originalEndTime = this.videoDuration; // **Set Original End Time**
      this.originalStartTime = this.startTime; // **Set Original Start Time**
      this.updateEndTime();
      this.cd.detectChanges();
      this.videoMetadataLoaded.emit();
    } else {
      console.error('Video element is not available in onVideoMetadataLoaded');
    }
  }

  private updateEndTime() {
    this.log('Updating end time');
    this.endTime = this.videoDuration;
    this.timeForm.patchValue({
      endTime: this.formatTime(this.endTime)
    }, { emitEvent: false });
    this.updateCurrentTimePosition();
    this.cd.detectChanges();
  }

  togglePlayPause() {
    this.log('togglePlayPause called');
    if (!this.isVideoLoaded || !this.videoElement) return;

    const video = this.videoElement.nativeElement;
    if (this.isPlaying) {
      video.pause();
      this.log('Video paused');
      this.isPlaying = false;
    } else {
      // Ensure the video starts within the trim range
      if (video.currentTime < this.startTime || video.currentTime > this.endTime) {
        video.currentTime = this.startTime;
        this.log(`Video time set to startTime: ${this.startTime}`);
      }
      video.play();
      this.log('Video playing');
      this.isPlaying = true;
      this.startGreenBallMovement();
    }
    this.cd.detectChanges();
  }

  async trimVideo() {
    if (!this.videoUrl || !this.worker) return;

    try {
      const videoBlob = await fetch(this.videoUrl).then(r => r.blob());
      const arrayBuffer = await videoBlob.arrayBuffer();
      
      this.worker.postMessage({
        command: 'trim',
        fileName: 'input.mp4',
        fileData: arrayBuffer,
        startTime: this.startTime,
        endTime: this.endTime
      }, [arrayBuffer]);
    } catch (error) {
      console.error('Error trimming video:', error);
    }
  }

  private updateTimeForm() {
    this.timeForm.patchValue({
      startTime: this.formatTime(this.startTime),
      endTime: this.formatTime(this.endTime)
    }, { emitEvent: false });
  }

  onTimeUpdate(event: Event): void {
    const video = (event.target as HTMLVideoElement);
    this.currentTime = video.currentTime;
    this.log(`onTimeUpdate: currentTime=${this.currentTime}`);

    if (video.currentTime >= this.endTime) {
      video.pause();
      video.currentTime = this.startTime;
      this.seekTo.emit(this.startTime);
      this.isPlaying = false;
      this.log('Video reached endTime. Paused and seeked to startTime.');
      this.updateCurrentTimePosition();
      this.cd.detectChanges();
    } else {
      this.updateCurrentTimePosition();
    }

    if (this.isPlaying) {
      this.updateCurrentTimePosition();
    }

    this.videoTimeUpdate.emit();
  }

  onSliderChange(type: 'start' | 'end') {
    if (type === 'start') {
      this.startTime = Math.min(this.startTime, this.endTime);
    } else {
      this.endTime = Math.max(this.endTime, this.startTime);
    }
    this.cd.detectChanges();
  }

  startGreenBallMovement() {
    this.log('startGreenBallMovement called');
    this.updateCurrentTimePosition();
  }

  updateCurrentTimePosition() {
    if (!this.isVideoLoaded) {
      this.currentTimePosition = 0;
      this.log('Current time position set to 0 (video not loaded)');
      return;
    }

    const video = this.videoElement.nativeElement;
    const currentTime = video.currentTime;

    if (currentTime < this.startTime) {
      this.currentTimePosition = (0 / this.videoDuration) * 100;
      this.log(`Current time position set based on currentTime < startTime: ${this.currentTimePosition}%`);
      return;
    }

    if (currentTime > this.endTime) {
      this.currentTimePosition = (this.endTime / this.videoDuration) * 100;
      this.log(`Current time position set based on currentTime > endTime: ${this.currentTimePosition}%`);
      return;
    }

    this.currentTimePosition = (currentTime / this.videoDuration) * 100;
    this.log(`Current time position updated to: ${this.currentTimePosition}%`);
  }

  seekToPosition(time: number): void {
    if (this.videoElement && this.videoElement.nativeElement) {
      this.videoElement.nativeElement.currentTime = time;
      this.updateCurrentTimePosition();
    }
  }

  onTimeInputBlur(control: 'startTime' | 'endTime') {
    this.log(`onTimeInputBlur called for ${control}`);
    const value = this.timeForm.get(control)?.value;
    const timeInSeconds = this.parseTime(value);

    if (control === 'startTime') {
      this.startTime = Math.min(timeInSeconds, this.endTime);
      this.log(`startTime updated to: ${this.startTime}`);
    } else {
      this.endTime = Math.max(timeInSeconds, this.startTime);
      this.log(`endTime updated to: ${this.endTime}`);
    }

    this.updateCurrentTimePosition();
    this.cd.detectChanges();
  }

  onKeyDown(event: KeyboardEvent, control: 'startTime' | 'endTime') {
    if (event.key === 'Enter') {
      this.log(`Enter key pressed on ${control} input`);
      this.onTimeInputBlur(control);
    }
  }

  startDragging(type: 'start' | 'end' | 'current', event: MouseEvent) {
    this.log(`startDragging called with type=${type}`);
    event.preventDefault();
    this.dragging = type;
    this.sliderRect = (event.target as HTMLElement).closest('.bg-gray-200')?.getBoundingClientRect() || null;

    window.addEventListener('mousemove', this.onDrag);
    window.addEventListener('mouseup', this.stopDragging);
    this.log(`Dragging started: ${type}`);
  }

  onDrag = (event: MouseEvent) => {
    if (!this.dragging || !this.sliderRect) return;

    const mouseX = event.clientX - this.sliderRect.left;
    let ratio = mouseX / this.sliderRect.width;
    ratio = Math.max(0, Math.min(ratio, 1)); // Clamp between 0 and 1
    const newTime = Math.round(ratio * this.videoDuration);

    this.log(`onDrag: mouseX=${mouseX}, ratio=${ratio}, newTime=${newTime}`);

    switch (this.dragging) {
      case 'start':
        this.startTime = Math.min(newTime, this.endTime);
        if (this.isVideoLoaded) {
          this.videoElement.nativeElement.currentTime = this.startTime;
          this.log(`Video currentTime set to startTime: ${this.startTime}`);
        }
        break;
      case 'end':
        this.endTime = Math.max(newTime, this.startTime);
        this.log(`endTime updated to: ${this.endTime}`);
        break;
      case 'current':
        if (this.isVideoLoaded) {
          this.videoElement.nativeElement.currentTime = newTime;
          this.log(`Video currentTime set to: ${newTime}`);
        }
        break;
    }

    this.timeForm.patchValue({
      startTime: this.formatTime(this.startTime),
      endTime: this.formatTime(this.endTime)
    }, { emitEvent: false });

    this.seekTo.emit(this.dragging === 'start' ? this.startTime : this.endTime);
    this.updateCurrentTimePosition();
    this.cd.detectChanges();
  }

  stopDragging = () => {
    this.log('stopDragging called');
    this.dragging = null;
    this.sliderRect = null;
    window.removeEventListener('mousemove', this.onDrag);
    window.removeEventListener('mouseup', this.stopDragging);
    this.log('Dragging stopped');
  }

  formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  parseTime(time: string): number {
    const parts = time.split(':').map(part => parseInt(part, 10));
    if (parts.length !== 2 || parts.some(isNaN)) {
      this.log(`parseTime: Invalid time format received: ${time}`);
      return 0;
    }
    const totalSeconds = parts[0] * 60 + parts[1];
    this.log(`parseTime: Parsed ${time} to ${totalSeconds} seconds`);
    return totalSeconds;
  }

  pad(num: number): string {
    return num < 10 ? '0' + num : num.toString();
  }

  private logVideoState() {
    this.logGroup('VideoEditComponent: Video State');
    this.log('Start Time:', this.startTime);
    this.log('End Time:', this.endTime);
    this.log('Is Video Loaded:', this.isVideoLoaded);
    this.log('Is Playing:', this.isPlaying);
    this.log('Current Time Position:', this.currentTimePosition);
    if (this.videoElement && this.videoElement.nativeElement) {
      const video = this.videoElement.nativeElement;
      this.log('Video Element Ready State:', video.readyState);
      this.log('Video Element Source:', video.src);
      this.log('Video Element Duration:', video.duration);
    } else {
      this.log('Video Element: Not Available');
    }
    this.logGroupEnd();
  }

  async checkFFmpegVersion() {
    console.log('FFmpeg version check is not available');
  }

  onFileSelected(event: Event) {
    const input = event.target as HTMLInputElement;
    if (input.files && input.files.length > 0) {
      this.videoFile = input.files[0];
      this.loadVideo();
    }
  }

  loadVideo() {
    if (this.videoFile) {
      const videoElement = this.videoElement.nativeElement;
      videoElement.src = URL.createObjectURL(this.videoFile);
      videoElement.onloadedmetadata = () => {
        this.videoDuration = Math.floor(videoElement.duration);
        this.endTime = this.videoDuration;
        this.cd.detectChanges();
      };
    }
  }

  downloadVideo() {
    if (this.trimmedVideoUrl) {
      const a = document.createElement('a');
      a.href = this.trimmedVideoUrl;
      a.download = 'trimmed_video.mp4';
      document.body.appendChild(a);
      a.click();
      document.body.removeChild(a);
    }
  }

  async loadFFmpeg() {
    try {
      await this.videoTrimService.load();
      console.log('FFmpeg loaded successfully');
    } catch (error) {
      console.error('Error loading FFmpeg:', error);
    }
  }

  private setupWorkerSubscriptions() {
    // Subscribe to worker messages
    if (this.subscription && this.ffmpegWorkerService.messages$) {
      this.subscription.add(
        this.ffmpegWorkerService.messages$.subscribe((data) => {
          console.log('Received from worker:', data);
          // Handle different message types
          switch (data.type) {
            case 'log':
              console.log('FFmpeg Log:', data.message);
              break;
            case 'progress':
              console.log(`Progress [${data.stage}]: ${data.message}`);
              break;
            case 'trimmed':
              console.log('Trimmed Video:', data);
              // Handle the trimmed video data
              break;
            case 'error':
              console.error('Worker Error:', data.message);
              break;
            default:
              console.warn('Unknown message type:', data);
          }
        })
      );
    }
    // Subscribe to worker errors
    if (this.subscription && this.ffmpegWorkerService.errors$) {
      this.subscription.add(
        this.ffmpegWorkerService.errors$.subscribe((error) => {
          console.error('Worker encountered an error:', error);
        })
      );
    }
  }

  toggleTrimControls() {
    this.showTrimControls = !this.showTrimControls;
    if (this.showTrimControls) {
      this.showInfoAlert = true;
      if (!this.loaded) {
        this.loadFFmpeg();
      }
    }
  }

  closeInfoAlert() {
    this.showInfoAlert = false;
  }

  async handleTrim(): Promise<void> {
    if (this.trimButtonState !== 'idle') {
      return;
    }

    this.trimButtonState = 'loading';
    this.toggleTrimControls();

    try {
      // Simulate loading editing tools
      await this.delay(1000);

      this.trimButtonState = 'performing';
      await this.trimVideo();

      // Ensure "Performing Trim" is shown for at least 2 seconds
      await this.delay(2000);

      // Set savingVideo to true
      this.savingVideo = true;

      // Save the trimmed video
      if (this.trimmedVideoUrl) {
        const response = await fetch(this.trimmedVideoUrl);
        const blob = await response.blob();
        const fileName = this.getEditedFilename(this.originalFilename);
        const userId = await this.userService.getCurrentUserId();
        
        if (userId) {
          const result = await this.recordingService.saveEditedRecording({ blob, fileName }, userId);
          if (result.success && result.data) {
            console.log('Trimmed video saved successfully. URL:', result.data.downloadURL);
            this.videoTrimmed.emit({ url: result.data.downloadURL, filename: fileName });
          } else {
            console.error('Failed to save trimmed video');
          }
        } else {
          console.error('User ID not available');
        }
      }

      // Reset trim button state to idle after trimming and saving
      this.trimButtonState = 'idle';
      this.savingVideo = false;
    } catch (error) {
      console.error('Error during trimming or saving:', error);
      this.trimButtonState = 'idle';
      this.savingVideo = false;
    }
  }

  private delay(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }

  // **Getter to Check if Trim is Enabled**
  get isTrimEnabled(): boolean {
    return this.startTime !== this.originalStartTime || this.endTime !== this.originalEndTime;
  }

  // **Method to Show Tooltip**
  showDisabledTooltip(): void {
    this.showTooltip = true;
    setTimeout(() => {
      this.showTooltip = false;
    }, 3000); // Tooltip disappears after 3 seconds
  }

  // **Updated Handle Trim Button Click**
  onTrimButtonClick(): void {
    if (this.isTrimEnabled) {
      this.handleTrim();
    } else {
      this.showDisabledTooltip();
    }
  }

  private getEditedFilename(originalFilename: string): string {
    const baseFilename = originalFilename.split('.')[0];
    const extension = originalFilename.split('.').pop();
    const timestamp = new Date().getTime();
    return `${baseFilename}_edited_${timestamp}.${extension}`;
  }

  private initializeWorker() {
    if (typeof Worker !== 'undefined') {
      this.worker = new Worker(new URL('../../workers/ffmpeg-worker.ts', import.meta.url));
      this.worker.onmessage = ({ data }) => this.handleWorkerMessage(data);
      this.worker.postMessage({ command: 'load' });
    } else {
      console.error('Web Workers are not supported in this environment.');
    }
  }

  private handleWorkerMessage(data: any) {
    switch (data.type) {
      case 'log':
        console.log(data.message);
        break;
      case 'progress':
        this.ffmpegStatus = data.message;
        this.ffmpegLoadProgress = data.stage === 'complete' ? 100 : 50; // You can adjust this logic
        this.cd.detectChanges();
        break;
      case 'loaded':
        this.ffmpegLoading = false;
        this.workerReady = true;
        this.cd.detectChanges();
        break;
      case 'error':
        console.error(data.message);
        this.ffmpegError = data.message;
        this.cd.detectChanges();
        break;
      case 'trimmed':
        this.handleTrimmedVideo(data);
        break;
    }
  }

  private handleTrimmedVideo(data: any) {
    // Handle the trimmed video data
    const blob = new Blob([data.data], { type: 'video/mp4' });
    this.trimmedVideoUrl = URL.createObjectURL(blob);
    this.videoUrl = this.trimmedVideoUrl;
    this.videoTrimmed.emit({ url: this.trimmedVideoUrl, filename: data.fileName });
    this.showTrimmedAlert = true;
    this.cd.detectChanges();
  }
}