import { Injectable, Inject, PLATFORM_ID, inject, EventEmitter } from '@angular/core';
import { BehaviorSubject, fromEvent, Subscription } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { DOCUMENT, isPlatformBrowser } from '@angular/common';

const DEBUG_MODE = false; // Set this to true to enable logging

@Injectable({
  providedIn: 'root'
})
export class ResizeService {
  private resizingEnabled = true;
  private setupCompleteSubject = new BehaviorSubject<boolean>(false);
  setupComplete$ = this.setupCompleteSubject.asObservable();

  // Add this line to define the resizeComplete event
  resizeComplete = new EventEmitter<void>();

  private resizeSubscription!: Subscription;
  private currentContainer: HTMLElement | null = null;
  private isWebcamOn = false;

  private platformId = inject(PLATFORM_ID);
  private document = inject(DOCUMENT);

  constructor() {
    this.log('ResizeService instantiated');
    if (isPlatformBrowser(this.platformId)) {
      this.initializeResizeListener();
    }
  }

  /**
   * Initializes the window resize listener.
   */
  private initializeResizeListener(): void {
    this.resizeSubscription = fromEvent(window, 'resize')
      .pipe(debounceTime(200))
      .subscribe(() => {
        this.log('Window resized');
        this.handleResize();
      });
  }

  /**
   * Handles the resize event.
   */
  private handleResize(): void {
    if (this.currentContainer && this.isWebcamOn) {
      this.adjustWebcamContainerSize(this.currentContainer, this.isWebcamOn);
    }
  }

  /**
   * Adjusts the webcam container size based on the viewport.
   * @param container The webcam container element.
   * @param isWebcamOn Indicates if the webcam is active.
   */
  adjustWebcamContainerSize(container: HTMLElement, isWebcamOn: boolean): void {
    this.logGroup('ResizeService: adjustWebcamContainerSize');

    if (!this.resizingEnabled || !isWebcamOn || !isPlatformBrowser(this.platformId)) {
      this.log('Resizing is disabled, webcam is off, or not in browser. Skipping adjustment.');
      this.logGroupEnd();
      return;
    }

    this.currentContainer = container;
    this.isWebcamOn = isWebcamOn;

    const viewportWidth = window.innerWidth;
    const isLargeViewport = viewportWidth >= 1024; // Tailwind's 'lg' breakpoint

    if (isLargeViewport) {
      // Maintain aspect ratio for large viewports
      container.style.position = 'relative';
      container.style.top = '';
      container.style.left = '';
      container.style.width = '100%';
      const desiredAspectRatio = 16 / 9; // Example aspect ratio
      const newHeight = container.clientWidth / desiredAspectRatio;
      container.style.height = `${newHeight}px`;
      this.log(`Set container height to ${newHeight}px to maintain aspect ratio ${desiredAspectRatio}`);
    } else {
      // Full-screen for smaller viewports with 1px top margin
      
      container.style.top = '1px';
      container.style.left = '0';
      container.style.width = '100vw';
      container.style.height = 'calc(100vh - 1px)';
      container.style.display = 'flex';
      container.style.justifyContent = 'center';
      container.style.alignItems = 'center';
      this.log('Set container to full screen with 1px top margin');
    }

    // Emit the resizeComplete event
    setTimeout(() => this.resizeComplete.emit(), 0);

    this.logGroupEnd();
  }

  /**
   * Adjusts the canvas size while maintaining the aspect ratio.
   * @param canvasElement The canvas element.
   * @param videoElement The video element.
   * @param containerElement The container element.
   * @returns The drawing dimensions.
   */
  adjustCanvasSize(
    canvasElement: HTMLCanvasElement,
    videoElement: HTMLVideoElement,
    containerElement: HTMLElement
  ): { drawWidth: number; drawHeight: number; xOffset: number; yOffset: number } {
    this.logGroup('ResizeService: adjustCanvasSize');
    this.log('Resizing enabled:', this.resizingEnabled);
    if (!this.resizingEnabled) {
      this.log('Resizing skipped');
      this.logGroupEnd();
      return {
        drawWidth: videoElement.videoWidth,
        drawHeight: videoElement.videoHeight,
        xOffset: 0,
        yOffset: 0
      };
    }

    const viewportWidth = window.innerWidth;
    const isLargeViewport = viewportWidth >= 1024; // Tailwind 'lg' breakpoint
    let drawWidth: number, drawHeight: number, xOffset: number, yOffset: number;

    if (isLargeViewport) {
      const containerWidth = containerElement.clientWidth;
      const desiredAspectRatio = videoElement.videoWidth / videoElement.videoHeight;
      const newHeight = containerWidth / desiredAspectRatio;

      drawWidth = containerWidth;
      drawHeight = newHeight;
      xOffset = 0;
      yOffset = 0;
    } else {
      // Full-screen handling with object-cover
      const containerWidth = containerElement.clientWidth;
      const containerHeight = containerElement.clientHeight;
      const videoAspectRatio = videoElement.videoWidth / videoElement.videoHeight;
      const containerAspectRatio = containerWidth / containerHeight;

      if (containerAspectRatio > videoAspectRatio) {
        drawWidth = containerWidth;
        drawHeight = containerWidth / videoAspectRatio;
        xOffset = 0;
        yOffset = (containerHeight - drawHeight) / 2;
      } else {
        drawHeight = containerHeight;
        drawWidth = containerHeight * videoAspectRatio;
        xOffset = (containerWidth - drawWidth) / 2;
        yOffset = 0;
      }
    }

    // Set canvas dimensions to match display size
    canvasElement.width = Math.round(drawWidth);
    canvasElement.height = Math.round(drawHeight);

    // Apply styles
    canvasElement.style.width = '100%';
    canvasElement.style.height = '100%';
    canvasElement.style.objectFit = 'cover';
    canvasElement.style.position = 'absolute';
    canvasElement.style.left = '0';
    canvasElement.style.top = '0';
    canvasElement.style.visibility = 'visible';

    this.log(`Canvas resized to ${canvasElement.width}x${canvasElement.height}`);
    this.logGroupEnd();
    return { drawWidth, drawHeight, xOffset, yOffset };
  }

  /**
   * Adjusts the video size while maintaining the aspect ratio.
   * @param videoElement The video element.
   * @param containerElement The container element.
   */
  adjustVideoSize(
    videoElement: HTMLVideoElement,
    containerElement: HTMLElement
  ): void {
    this.logGroup('ResizeService: adjustVideoSize');
    if (!this.resizingEnabled) {
      this.log('Resizing skipped');
      this.logGroupEnd();
      return;
    }

    const viewportWidth = window.innerWidth;
    const isLargeViewport = viewportWidth >= 1024; // Tailwind 'lg' breakpoint

    if (isLargeViewport) {
      this.log(`Video resized for large viewport`);
    } else {
      this.log('Video resized for small viewport');
    }

    // Remove width and height attributes
    videoElement.removeAttribute('width');
    videoElement.removeAttribute('height');

    videoElement.style.width = '100%';
    videoElement.style.height = '100%';
    videoElement.style.objectFit = 'cover';
    videoElement.style.position = 'absolute';
    videoElement.style.left = '0';
    videoElement.style.top = '0';
    videoElement.style.visibility = 'visible';
    videoElement.style.display = 'block';

    this.logGroupEnd();
  }

  /**
   * Logs dimensions when webcam is off.
   */
  logDimensionsWebcamOff(
    platformId: Object,
    containerElement: HTMLElement | null,
    isLargeViewport: boolean,
    detectionMode: boolean
  ): void {
    if (!this.resizingEnabled || !containerElement) return;
    this.log('Webcam Off - Container Dimensions:', containerElement.clientWidth, 'x', containerElement.clientHeight);
    // Additional logging or handling can be added here
  }

  /**
   * Logs dimensions when webcam is on.
   */
  logDimensionsWebcamOn(
    platformId: Object,
    videoElement: HTMLVideoElement | null,
    canvasElement: HTMLCanvasElement | null,
    containerElement: HTMLElement | null,
    isLargeViewport: boolean,
    detectionMode: boolean
  ): void {
    if (!this.resizingEnabled || !videoElement || !canvasElement || !containerElement) return;
    this.log('Webcam On - Video Dimensions:', videoElement.clientWidth, 'x', videoElement.clientHeight);
    this.log('Webcam On - Canvas Dimensions:', canvasElement.clientWidth, 'x', canvasElement.clientHeight);
    this.log('Webcam On - Container Dimensions:', containerElement.clientWidth, 'x', containerElement.clientHeight);
    // Additional logging or handling can be added here
  }

  /**
   * Enables or disables resizing.
   * @param enabled Boolean indicating whether resizing is enabled.
   */
  setResizingEnabled(enabled: boolean): void {
    this.log('ResizeService: setResizingEnabled', enabled);
    this.resizingEnabled = enabled;
  }

  /**
   * Sets the setup completion state.
   * @param complete Boolean indicating whether setup is complete.
   */
  setSetupComplete(complete: boolean): void {
    this.log('ResizeService: setSetupComplete', complete);
    this.setupCompleteSubject.next(complete);
  }

  /**
   * Cleans up subscriptions when the service is destroyed.
   */
  ngOnDestroy(): void {
    if (isPlatformBrowser(this.platformId) && this.resizeSubscription) {
      this.resizeSubscription.unsubscribe();
      this.log('ResizeService destroyed and subscriptions unsubscribed');
    }
  }

  /**
   * Logs messages if DEBUG_MODE is enabled.
   * @param args The messages or data to log.
   */
  private log(...args: any[]): void {
    if (DEBUG_MODE) {
      console.log(...args);
    }
  }

  /**
   * Logs grouped messages if DEBUG_MODE is enabled.
   * @param label The label for the console group.
   */
  private logGroup(label: string): void {
    if (DEBUG_MODE) {
      console.group(label);
    }
  }

  /**
   * Ends a console group if DEBUG_MODE is enabled.
   */
  private logGroupEnd(): void {
    if (DEBUG_MODE) {
      console.groupEnd();
    }
  }
}