import { Injectable, inject, signal, effect, Injector, runInInjectionContext, OnInit } from '@angular/core';
import { Firestore, collection, collectionData } from '@angular/fire/firestore';
import { Observable, BehaviorSubject } from 'rxjs';
import { map } from 'rxjs/operators';
import { UnifiedSoundProfile, DeviceType, DEVICE_TYPES } from '@models/sound-profile.model';
import { toObservable } from '@angular/core/rxjs-interop';
import { SoundProfileService } from './sound-profile.service';

// Constants for audio detection
const DEBUG_MODE = false;
const FFT_SIZE = 1024;
const SAMPLE_RATE = 44100;
const LOW_FREQUENCY_THRESHOLD = 400; // Hz
const FREQUENCY_TOLERANCE = 300; // Hz
const CONSISTENCY_TOLERANCE = 0.3;
const LOW_FREQUENCY_ENERGY_RATIO_TOLERANCE = 0.2;
const AMPLITUDE_TOLERANCE = 0.3;
const THRESHOLD_REDUCTION_FACTOR = 0.8;

const RECALIBRATION_INTERVAL = 5000; // Recalibrate every 5 seconds

interface AudioFeatures {
  peakFrequency: number;
  consistencyScore: number;
  averageAmplitude: number;
  lowFrequencyEnergyRatio: number;
  spectralRolloff: number;
  zeroCrossingRate: number;
}

@Injectable({
  providedIn: 'root'
})
export class AudioDetectionService implements OnInit {
  private firestore: Firestore = inject(Firestore);
  private soundProfileService = inject(SoundProfileService);

  private audioContext: AudioContext | null = null;
  public analyser: AnalyserNode | null = null;
  private mediaStreamSource: MediaStreamAudioSourceNode | null = null;
  public dataArray: Uint8Array | null = null;

  private soundProfiles: { [key: string]: UnifiedSoundProfile[] } = {
    blow: [],
    laugh: [],
    clap: [],
    hello: []
  };

  private isInitialized = false;
  private isListening = false;
  private injector = inject(Injector);
  private isWarmingUp = false;
  private warmUpStartTime: number | null = null;

  private detectionThreshold = 0.7;
  private detectionInterval = 100; // ms
  private sampleRate = SAMPLE_RATE;
  private fftSize = FFT_SIZE;

  private detectedSoundSignal = signal<string | null>(null);
  private validBreathDetectedSignal = signal<boolean>(false);

  private audioDataSubject = new BehaviorSubject<Uint8Array | null>(null);
  public audioData$ = this.audioDataSubject.asObservable();

  private matchedProfileSubject = new BehaviorSubject<UnifiedSoundProfile | null>(null);
  public matchedProfile$ = this.matchedProfileSubject.asObservable();
  
  private blowDetectedSignal = signal<boolean>(false);
  private blowProfiles: UnifiedSoundProfile[] = [];

  private recalibrationTimer: any;

  private currentDeviceType: DeviceType = 'desktop + webcam'; // Default device type

  constructor() {
    this.loadSoundProfiles();
    this.loadBlowProfiles();
  }

  ngOnInit() {
    effect(() => {
      if (this.soundProfileService.profilesLoaded()) {
        this.startListening();
      }
    });
  }

  public reset(): void {
    this.stopListening();
    this.audioContext = null;
    this.analyser = null;
    this.mediaStreamSource = null;
    this.dataArray = null;
    this.isInitialized = false;
    this.isListening = false;
    this.detectedSoundSignal.set(null);
    this.validBreathDetectedSignal.set(false);
    this.blowDetectedSignal.set(false);
    this.isWarmingUp = false;
    this.warmUpStartTime = null;
    this.clearRecalibrationTimer();
  }

  async initAudio(stream: MediaStream): Promise<void> {
    if (this.isInitialized) {
      if (DEBUG_MODE) console.log('Audio already initialized, resetting...');
      this.reset();
    }
    
    try {
      this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
      this.analyser = this.audioContext.createAnalyser();
      this.analyser.fftSize = this.fftSize;
      this.mediaStreamSource = this.audioContext.createMediaStreamSource(stream);
      this.mediaStreamSource.connect(this.analyser);
      this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
      this.isInitialized = true;
      this.isWarmingUp = true;
      this.warmUpStartTime = Date.now();
      if (DEBUG_MODE) console.log('Audio initialized successfully');
      this.startRecalibrationTimer();
    } catch (error) {
      console.error('Error initializing audio:', error);
      throw error;
    }
  }

  startListening(): void {
    if (!this.isInitialized) {
      console.error('Audio not initialized. Call initAudio() first.');
      return;
    }

    if (this.isListening) {
      if (DEBUG_MODE) console.log('Already listening');
      return;
    }

    this.isListening = true;
    this.detectSound();
    this.startRecalibrationTimer();
  }

  stopListening(): void {
    this.isListening = false;
    this.clearRecalibrationTimer();
  }

  private startRecalibrationTimer(): void {
    this.clearRecalibrationTimer();
    this.recalibrationTimer = setInterval(() => this.recalibrate(), RECALIBRATION_INTERVAL);
  }

  private clearRecalibrationTimer(): void {
    if (this.recalibrationTimer) {
      clearInterval(this.recalibrationTimer);
      this.recalibrationTimer = null;
    }
  }

  private async recalibrate(): Promise<void> {
    if (DEBUG_MODE) console.log('Recalibrating audio detection...');
    
    // Reload sound profiles
    await this.loadSoundProfiles();
    await this.loadBlowProfiles();
    
    // Reset audio context
    if (this.audioContext) {
      await this.audioContext.close();
    }
    
    // Reinitialize audio
    if (this.mediaStreamSource?.mediaStream) {
      await this.initAudio(this.mediaStreamSource.mediaStream);
    } else {
      console.error('No media stream available for recalibration');
    }
    
    // Restart listening
    this.startListening();
    
    if (DEBUG_MODE) console.log('Recalibration complete');
  }

  private async detectSound(): Promise<void> {
    if (!this.isListening || !this.analyser || !this.dataArray) return;

    this.analyser.getByteFrequencyData(this.dataArray);
    this.audioDataSubject.next(this.dataArray);

    const features = this.extractFeatures(this.dataArray);
    if (DEBUG_MODE) console.log('Extracted features:', features);

    const matchedProfile = this.compareAudioWithProfiles(this.dataArray);
    if (DEBUG_MODE) console.log('Matched profile:', matchedProfile);

    this.matchedProfileSubject.next(matchedProfile);

    if (matchedProfile && matchedProfile.type === 'blow') {
      this.detectedSoundSignal.set('blow');
      this.validBreathDetectedSignal.set(true);
    } else {
      this.detectedSoundSignal.set(matchedProfile ? matchedProfile.type : null);
      this.validBreathDetectedSignal.set(false);
    }

    setTimeout(() => this.detectSound(), this.detectionInterval);
  }

  private compareAudioWithProfiles(audioData: Uint8Array): UnifiedSoundProfile | null {
    const features = this.extractFeatures(audioData);
    if (DEBUG_MODE) console.log('Extracted features:', features);

    // Check if the features match any blow profile
    for (const profile of this.blowProfiles) {
      if (this.isAudioMatchingBlowProfile(features, profile)) {
        // Before confirming it's a blow, check against other profiles
        const nonBlowMatch = this.checkAgainstNonBlowProfiles(features);
        if (nonBlowMatch) {
          if (DEBUG_MODE) console.log('Matched non-blow profile:', nonBlowMatch);
          this.blowDetectedSignal.set(false);
          return nonBlowMatch;
        }
        if (DEBUG_MODE) console.log('Blow profile matched:', profile, 'Profile ID:', profile.id);
        this.blowDetectedSignal.set(true);
        return profile;
      }
    }

    if (DEBUG_MODE) console.log('No matching blow profile found');
    this.blowDetectedSignal.set(false);

    // Check other profiles
    return this.checkAgainstNonBlowProfiles(features);
  }

  private checkAgainstNonBlowProfiles(features: AudioFeatures): UnifiedSoundProfile | null {
    for (const profileType in this.soundProfiles) {
      if (profileType !== 'blow') {
        for (const profile of this.soundProfiles[profileType]) {
          if (this.isAudioMatchingProfile(features, profile)) {
            return profile;
          }
        }
      }
    }
    return null;
  }

  private findMatchingBlowProfile(features: AudioFeatures): UnifiedSoundProfile | null {
    for (const profile of this.blowProfiles) {
      if (this.isAudioMatchingBlowProfile(features, profile)) {
        return profile;
      }
    }
    return null;
  }

  private isAudioMatchingBlowProfile(features: AudioFeatures, profile: UnifiedSoundProfile): boolean {
    const frequencyTolerancePercent = 300; // 300% tolerance
    const consistencyTolerance = 1;
    const lowFrequencyEnergyRatioTolerance = 0.9;
    const amplitudeTolerance = 0.8;
    const thresholdMultiplier = 0.3;

    const frequencyDiffPercent = Math.abs(features.peakFrequency - profile.peakFrequency) / profile.peakFrequency * 100;
    const frequencyMatch = frequencyDiffPercent <= frequencyTolerancePercent;
    const consistencyMatch = Math.abs(features.consistencyScore - profile.consistencyScore) <= consistencyTolerance;
    const lowFrequencyEnergyRatioMatch = Math.abs(features.lowFrequencyEnergyRatio - profile.lowFrequencyEnergyRatio) <= lowFrequencyEnergyRatioTolerance;
    const amplitudeMatch = features.averageAmplitude >= profile.threshold * thresholdMultiplier &&
      Math.abs(features.averageAmplitude - profile.maxLevel) <= amplitudeTolerance;

    if (DEBUG_MODE) console.log('Matching blow profile:', {
      frequencyMatch,
      frequencyDiffPercent,
      consistencyMatch,
      lowFrequencyEnergyRatioMatch,
      amplitudeMatch,
      features,
      profile,
      thresholdValue: profile.threshold * thresholdMultiplier
    });

    return frequencyMatch && consistencyMatch && lowFrequencyEnergyRatioMatch && amplitudeMatch;
  }

  private extractFeatures(audioData: Uint8Array): AudioFeatures {
    const fftSize = this.fftSize;
    const sampleRate = this.sampleRate;

    // Calculate peak frequency
    const peakIndex = audioData.indexOf(Math.max(...audioData));
    const peakFrequency = (peakIndex * sampleRate) / (2 * fftSize);

    // Calculate consistency score (this is a simplified version, you might want to improve it)
    const sum = audioData.reduce((a, b) => a + b, 0);
    const mean = sum / audioData.length;
    const squaredDifferences = audioData.map(x => Math.pow(x - mean, 2));
    const variance = squaredDifferences.reduce((a, b) => a + b, 0) / audioData.length;

    // Normalize consistency score to be between 0 and 1
    const consistencyScore = Math.min(1, Math.max(0, 1 - (Math.sqrt(variance) / (mean + 1))));

    const averageAmplitude = mean;

    // Add calculation for lowFrequencyEnergyRatio
    const lowFrequencyBins = Math.floor((LOW_FREQUENCY_THRESHOLD / this.sampleRate) * this.fftSize);
    const lowFrequencyEnergy = audioData.slice(0, lowFrequencyBins).reduce((sum, value) => sum + value * value, 0);
    const totalEnergy = audioData.reduce((sum, value) => sum + value * value, 0);
    const lowFrequencyEnergyRatio = lowFrequencyEnergy / totalEnergy;

    // Add new features
    const spectralRolloff = this.calculateSpectralRolloff(audioData, sampleRate);
    const zeroCrossingRate = this.calculateZeroCrossingRate(audioData);

    return {
      peakFrequency,
      consistencyScore,
      averageAmplitude: averageAmplitude / 255, // Normalize to 0-1 range
      lowFrequencyEnergyRatio,
      spectralRolloff,
      zeroCrossingRate,
    };
  }

  private calculateSpectralRolloff(fftResult: Uint8Array, sampleRate: number): number {
    const totalEnergy = fftResult.reduce((sum, value) => sum + value, 0);
    let cumulativeEnergy = 0;
    const rolloffThreshold = 0.85 * totalEnergy;

    for (let i = 0; i < fftResult.length; i++) {
      cumulativeEnergy += fftResult[i];
      if (cumulativeEnergy >= rolloffThreshold) {
        return (i / fftResult.length) * (sampleRate / 2);
      }
    }
    return sampleRate / 2;
  }

  private calculateZeroCrossingRate(audioData: Uint8Array): number {
    let crossings = 0;
    for (let i = 1; i < audioData.length; i++) {
      if ((audioData[i] > 128 && audioData[i - 1] <= 128) || 
          (audioData[i] <= 128 && audioData[i - 1] > 128)) {
        crossings++;
      }
    }
    return crossings / audioData.length;
  }

  private isAudioMatchingProfile(features: AudioFeatures, profile: UnifiedSoundProfile): boolean {
    return (
      Math.abs(features.peakFrequency - profile.peakFrequency) <= FREQUENCY_TOLERANCE &&
      Math.abs(features.consistencyScore - profile.consistencyScore) <= CONSISTENCY_TOLERANCE &&
      Math.abs(features.lowFrequencyEnergyRatio - profile.lowFrequencyEnergyRatio) <= LOW_FREQUENCY_ENERGY_RATIO_TOLERANCE &&
      Math.abs(features.averageAmplitude - profile.maxLevel) <= AMPLITUDE_TOLERANCE &&
      features.averageAmplitude >= profile.threshold * THRESHOLD_REDUCTION_FACTOR
    );
  }

  analyzeAudio(audioData: Uint8Array): { detectedSound: string | null, features: AudioFeatures } {
    const features = this.extractFeatures(audioData);
    const detectedSound = this.classifySound(features);

    this.detectedSoundSignal.set(detectedSound);
    this.validBreathDetectedSignal.set(detectedSound === 'blow');

    return { detectedSound, features };
  }

  private classifySound(features: AudioFeatures): string | null {
    // Check if the features match any blow profile
    const matchedBlowProfile = this.findMatchingBlowProfile(features);
    if (matchedBlowProfile) {
      if (DEBUG_MODE) console.log('Blow detected:', matchedBlowProfile);
      this.blowDetectedSignal.set(true);
      return 'blow';
    } else {
      this.blowDetectedSignal.set(false);
    }

    // Check other profile types if it's not a blow
    for (const profileType in this.soundProfiles) {
      if (profileType !== 'blow') {
        for (const profile of this.soundProfiles[profileType]) {
          if (this.isAudioMatchingProfile(features, profile)) {
            return profileType;
          }
        }
      }
    }

    return null;
  }

  getDetectedSound(): Observable<string | null> {
    return new Observable<string | null>(observer => {
      const effectRef = effect(() => {
        observer.next(this.detectedSoundSignal());
      });

      return () => {
        effectRef.destroy();
      };
    });
  }

  validBreathDetected(): Observable<boolean> {
    return new Observable<boolean>(observer => {
      runInInjectionContext(this.injector, () => {
        const obs = toObservable(this.validBreathDetectedSignal);
        const subscription = obs.subscribe(observer);
        return () => subscription.unsubscribe();
      });
    });
  }

  getBlowDetectedSignal() {
    return this.blowDetectedSignal;
  }

  private async loadSoundProfiles(): Promise<void> {
    const profilesCollection = collection(this.firestore, 'config/soundSettings/soundProfiles');
    const profiles$ = collectionData(profilesCollection) as Observable<UnifiedSoundProfile[]>;

    profiles$.pipe(
      map(profiles => {
        const categorizedProfiles: { [key: string]: UnifiedSoundProfile[] } = {
          blow: [],
          laugh: [],
          clap: [],
          hello: []
        };
        profiles.forEach(profile => {
          if (profile.type in categorizedProfiles) {
            categorizedProfiles[profile.type].push(profile);
          }
        });
        return categorizedProfiles;
      })
    ).subscribe(
      categorizedProfiles => {
        this.soundProfiles = categorizedProfiles;
        if (DEBUG_MODE) console.log('Sound profiles loaded:', this.soundProfiles);
      },
      error => console.error('Error loading sound profiles:', error)
    );
  }

  private async loadBlowProfiles(): Promise<void> {
    this.blowProfiles = await this.soundProfileService.getProfilesByTypeAndDevice('blow', this.currentDeviceType);
  }

  async reloadSoundProfiles(): Promise<void> {
    await this.loadSoundProfiles();
    await this.loadBlowProfiles();
    this.soundProfileService.logLoadedProfiles();
  }

  getSampleRate(): number {
    return this.sampleRate;
  }

  setDetectionThreshold(threshold: number): void {
    this.detectionThreshold = threshold;
  }

  setDetectionInterval(interval: number): void {
    this.detectionInterval = interval;
  }

  setSampleRate(rate: number): void {
    this.sampleRate = rate;
  }

  setFFTSize(size: number): void {
    this.fftSize = size;
    if (this.analyser) {
      this.analyser.fftSize = size;
      this.dataArray = new Uint8Array(this.analyser.frequencyBinCount);
    }
  }

  async loadBlowProfilesForDeviceType(deviceType: DeviceType): Promise<void> {
    this.currentDeviceType = deviceType;
    await this.loadBlowProfiles();
    
    if (this.blowProfiles.length === 0) {
      console.log(`No blow profiles found for device type: ${deviceType}. Using all profiles.`);
      await this.loadAllBlowProfiles();
    } else {
      console.log(`Switched to device type: ${deviceType}`);
    }
    
    console.log('Loaded blow profiles:', this.blowProfiles);
  }

  private async loadAllBlowProfiles(): Promise<void> {
    this.blowProfiles = await this.soundProfileService.getProfilesByType('blow');
  }
}