import { Component, OnInit, OnDestroy, signal, computed, effect, inject, ElementRef, ViewChild, AfterViewInit, Input, DestroyRef, Injector, runInInjectionContext } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AudioDetectionService } from '@services/audio-detection.service';

const DEBUG_MODE = false; // Set to false in production
const ROTATION_DEBUG_MODE = false; // Set to false to turn off rotation debugging

@Component({
  selector: 'app-pinwheel-overlay',
  templateUrl: './pinwheel-overlay.component.html',
  styleUrls: ['./pinwheel-overlay.component.css'],
  standalone: true,
  imports: [CommonModule]
})
export class PinwheelOverlayComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('pinwheel') pinwheel!: ElementRef<HTMLDivElement>;

  @Input() isAnimating: boolean = false;  // Added Input
  @Input() audioLevel: number = 0;        // Added Input
  @Input() blowDetected = signal<boolean>(false);

  private readonly BLOW_ROTATION_SPEED = 0.5; // Very fast rotation when blow is detected
  private readonly DEFAULT_ROTATION_SPEED = 2; // Default normal speed

  private baseRotationSpeed = signal<number>(this.DEFAULT_ROTATION_SPEED);

  rotationSpeed = computed(() => {
    return this.blowDetected() ? this.BLOW_ROTATION_SPEED : this.baseRotationSpeed();
  });

  rotationAngle = signal(0);
  private continuousRotationAngle: number = 0;

  private readonly FASTEST_ROTATION_TIME = 0.5; // Fastest speed (0.5 seconds per rotation)
  private readonly SLOWEST_ROTATION_TIME = 5; // Slowest speed (10 seconds per rotation)
  private readonly THRESHOLD_BUFFER = 0.001; // Small buffer above threshold
  private readonly BREATH_INTENSITY_MULTIPLIER = 10; // Increased for more noticeable effect

  private decelerationStartTime: number | null = null;
  private readonly DECELERATION_DURATION = 2000; // 3 seconds in milliseconds

  private lastUpdateTime = 0;
  private animationFrameId: number | null = null;

  // Rotation debug variables
  private rotationHistory: number[] = [];
  private readonly MAX_ROTATION_HISTORY = 100; // Adjust as needed

  private readonly BASE_SCALE = 1; // Base scale of the pinwheel
  private readonly MAX_SCALE_INCREASE = 0.2; // Maximum scale increase

  pulsateEffect = computed(() => {
    const speedRatio = 1 - (this.rotationSpeed() / this.SLOWEST_ROTATION_TIME);
    const pulsateIntensity = speedRatio * this.MAX_SCALE_INCREASE;
    const pulsateScale = this.BASE_SCALE + pulsateIntensity * Math.sin(Date.now() / 100);
    return pulsateScale;
  });

  shakeEffect = computed(() => {
    const maxShake = 1; // Reduced maximum shake
    const shakeIntensity = Math.max(0, (this.SLOWEST_ROTATION_TIME - this.rotationSpeed()) / this.SLOWEST_ROTATION_TIME);
    const shakeX = Math.sin(Date.now() / 50) * maxShake * shakeIntensity;
    const shakeY = Math.cos(Date.now() / 50) * maxShake * shakeIntensity;
    return `translate(${shakeX}px, ${shakeY}px)`;
  });

  pinwheelTransform = computed(() => {
    const baseScale = 1.2 + (this.SLOWEST_ROTATION_TIME - this.rotationSpeed()) / this.SLOWEST_ROTATION_TIME * 0.6;
    const scale = baseScale + this.audioLevel * 0.5; // Scale based on audio level
    return `rotate(${this.rotationAngle()}deg) scale(${scale})`;
  });

  private injector = inject(Injector);

  private maxRotationStartTime: number | null = null;
  private readonly MAX_ROTATION_DURATION = 5000; // 5 seconds in milliseconds
  isColorChange = signal(false);
  private readonly DEFAULT_COLORS = ['#de744e', '#e58a6a', '#d65e32', '#e9a088', '#cd4826', '#edb6a3', '#de744e', '#f1ccc1'];
  private readonly WHITE_COLOR = '#FFFFFF';
  segmentColors = signal<string[]>(this.DEFAULT_COLORS);

  private readonly COLOR_CHANGE_THRESHOLD = 0.8; // 80% of max speed
  private readonly COLOR_CHANGE_DURATION = 3000; // 3 seconds in milliseconds
  private colorChangeStartTime: number | null = null;

  constructor(
    private audioDetectionService: AudioDetectionService,
    private destroyRef: DestroyRef
  ) { }

  ngOnInit(): void {
    this.log('PinwheelOverlayComponent initialized');
    runInInjectionContext(this.injector, () => {
      this.setupAnimationEffect();
      this.setupBlowDetectionEffect();
    });
  }

  private setupAnimationEffect(): void {
    effect(() => {
      if (this.isAnimating) {
        this.startAnimation();
      } else {
        this.stopAnimation();
      }
    }, { allowSignalWrites: true });
  }

  private setupBlowDetectionEffect(): void {
    effect(() => {
      if (this.blowDetected()) {
        this.log('Blow detected, increasing rotation speed');
        this.baseRotationSpeed.set(this.BLOW_ROTATION_SPEED);
        this.decelerationStartTime = null; // Reset deceleration start time
      } else {
        this.log('No blow detected, starting deceleration');
        this.startDeceleration();
      }
    }, { allowSignalWrites: true });
  }

  private startDeceleration(): void {
    if (this.decelerationStartTime === null) {
      this.decelerationStartTime = performance.now();
      this.decelerateGradually();
    }
  }

  private decelerateGradually(): void {
    if (this.decelerationStartTime === null) return;

    const currentTime = performance.now();
    const elapsedTime = currentTime - this.decelerationStartTime;
    const progress = Math.min(elapsedTime / this.DECELERATION_DURATION, 1);

    const newSpeed = this.BLOW_ROTATION_SPEED + (this.DEFAULT_ROTATION_SPEED - this.BLOW_ROTATION_SPEED) * progress;
    this.baseRotationSpeed.set(newSpeed);

    if (progress < 1) {
      requestAnimationFrame(() => this.decelerateGradually());
    } else {
      this.decelerationStartTime = null;
    }
  }

  ngAfterViewInit(): void {
    // Any AfterViewInit logic if needed
  }

  ngOnDestroy(): void {
    this.stopAnimation();
  }

  public accelerate(): void {
    this.log('Accelerating animation');
    const currentSpeed = this.baseRotationSpeed();
    const newSpeed = Math.max(this.FASTEST_ROTATION_TIME, currentSpeed * 0.8);
    this.baseRotationSpeed.set(newSpeed);
    this.log(`Accelerated to new speed: ${newSpeed}`);
  }

  public decelerate(): void {
    this.log('Decelerating animation');
    const currentSpeed = this.baseRotationSpeed();
    const newSpeed = Math.min(this.SLOWEST_ROTATION_TIME, currentSpeed * 1.2);
    this.baseRotationSpeed.set(newSpeed);
    this.log(`Decelerated to new speed: ${newSpeed}`);
  }

  private startAnimation() {
    if (this.animationFrameId === null) {
      this.log('Starting animation');
      const animate = (timestamp: number) => {
        if (!this.lastUpdateTime) this.lastUpdateTime = timestamp;
        const deltaTime = timestamp - this.lastUpdateTime;

        // Calculate new rotation angle based on rotation speed
        // Change the sign here to make it rotate clockwise
        const rotationIncrement = -(360 * deltaTime) / (this.rotationSpeed() * 1000);
        this.continuousRotationAngle += rotationIncrement;
        // Use Math.abs to ensure we're always dealing with positive angles
        this.rotationAngle.set(Math.abs(this.continuousRotationAngle % 360));

        if (ROTATION_DEBUG_MODE) {
          this.logRotation(this.continuousRotationAngle);
        }

        // Adjust rotation speed based on audio level
        const speedAdjustment = this.audioLevel * 0.5; // Reduced multiplier for smoother speed changes
        const newSpeed = Math.max(this.FASTEST_ROTATION_TIME,
          Math.min(this.SLOWEST_ROTATION_TIME,
            this.baseRotationSpeed() - speedAdjustment));
        this.baseRotationSpeed.set(newSpeed);

        // Check if rotation speed is above the threshold for color change
        const speedRatio = (this.SLOWEST_ROTATION_TIME - this.rotationSpeed()) / (this.SLOWEST_ROTATION_TIME - this.FASTEST_ROTATION_TIME);
        if (speedRatio >= this.COLOR_CHANGE_THRESHOLD) {
          if (this.colorChangeStartTime === null) {
            this.colorChangeStartTime = timestamp;
          } else if (timestamp - this.colorChangeStartTime >= this.COLOR_CHANGE_DURATION) {
            this.updateColors(true);
          }
        } else {
          if (this.colorChangeStartTime !== null) {
            this.updateColors(false);
            this.colorChangeStartTime = null;
          }
        }

        this.lastUpdateTime = timestamp;
        this.animationFrameId = requestAnimationFrame(animate);
      };
      this.animationFrameId = requestAnimationFrame(animate);
    }
  }

  private updateColors(changeToWhite: boolean): void {
    if (changeToWhite) {
      const whiteColors = Array(this.segmentColors().length).fill(this.WHITE_COLOR);
      this.segmentColors.set(whiteColors);
    } else {
      this.segmentColors.set(this.DEFAULT_COLORS);
    }
  }

  private stopAnimation() {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
    this.lastUpdateTime = 0; // Reset for next start
  }

  private logRotation(angle: number): void {
    const normalizedAngle = angle % 360;
    console.log(`Rotation angle: ${normalizedAngle.toFixed(2)}`);
  }

  private log(message: string, level: 'log' | 'error' = 'log'): void {
    if (DEBUG_MODE) {
      if (level === 'error') {
        console.error(`PinwheelOverlayComponent: ${message}`);
      } else {
        console.log(`PinwheelOverlayComponent: ${message}`);
      }
    }
  }

  private calculateNormalRotationSpeed(): number {
    // Implement your normal rotation speed calculation here
    // This could be based on audioLevel or other factors
    return 10; // Default speed
  }
}
