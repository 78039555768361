import { Component, OnInit, OnDestroy, AfterViewInit, ViewChild, Input, Output, EventEmitter, Inject, PLATFORM_ID, ChangeDetectorRef, NgZone, computed, inject, Signal, signal, Injector, effect, ElementRef, HostListener, DestroyRef, runInInjectionContext } from '@angular/core';
import { CommonModule, isPlatformBrowser, DOCUMENT } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from '@shared/shared.module';
import { FormatTimePipe } from '@pipes/format-time.pipe';
import { Subscription, Subject, BehaviorSubject } from 'rxjs';
import { take } from 'rxjs/operators';

import { Router, NavigationStart, ActivatedRoute } from '@angular/router';
import { AudioDetectionService } from '@services/audio-detection.service';
import { openDB } from 'idb';
import { ModeOverlayComponent } from '@components/overlays/mode-overlay/mode-overlay.component';
import { Firestore, doc, getDoc, collection, addDoc, writeBatch, getDocs, updateDoc, Timestamp } from '@angular/fire/firestore';
import { UserService } from '@services/user.service';

import { RecordingService } from '@services/recording.service';
import { ToastService } from '@services/toast.service';
import { DebugLoggerService } from '@services/debug-logger.service';
import { ResizeService } from '@services/resize.service';
import { CanvasDrawService } from '@services/canvas-draw.service';
import { RecordingTimerService } from '@services/recording-timer.service';
import { SoundProfileService } from '@services/sound-profile.service';
import { WebcamControlService } from '@services/webcam-control.service';
import { debounce } from 'lodash';
import { DEVICE_TYPES, DeviceType } from '@models/sound-profile.model';
import { LoginModalService } from '@shared/services/login-modal.service';


const DEBUG_MODE = false; // Set this to false in production

@Component({
  selector: 'app-webcam',
  templateUrl: './webcam.component.html',
  styleUrls: ['./webcam.component.css'],
  standalone: true,
  imports: [CommonModule, FormsModule, SharedModule, FormatTimePipe, ModeOverlayComponent]
})
export class WebcamComponent implements OnInit, OnDestroy, AfterViewInit {
  @ViewChild('videoElement') videoElement!: ElementRef<HTMLVideoElement>;
  @ViewChild('canvasElement') canvasElement!: ElementRef<HTMLCanvasElement>;
  @ViewChild('webcamContainer') webcamContainer!: ElementRef<HTMLDivElement>;
  private recordingCanvas: HTMLCanvasElement | null = null;
  private debouncedCheckViewportSize: () => void;
  private isCountdownActive = false;

  @Input() detectionMode = false;
  @Input() demoMode = false;
  @Output() demoModeChange = new EventEmitter<boolean>();
  @Output() saveVideo = new EventEmitter<{ downloadURL: string; filename: string; }>();
  @Output() sendVideo = new EventEmitter<void>();
  @Output() videoRecorded = new EventEmitter<{ blob: Blob, duration: number }>();
  @Output() recordingFinished = new EventEmitter<Blob | string>();
  @Output() modeSelected = new EventEmitter<'Pinwheel' | 'Bubbles' | 'Music'>();
  @Output() recordingStarted = new EventEmitter<void>();
  @Output() videoSaved = new EventEmitter<Blob>();
  @Output() videoDeleted = new EventEmitter<void>();
  @Output() closeModal = new EventEmitter<string | Event>();
  @Output() webcamActiveChange = new EventEmitter<boolean>();
  @Output() uploadComplete = new EventEmitter<{ filename: string, url: string }>();
  @Output() recordingSaved = new EventEmitter<{ filename: string, url: string }>();
  @Output() recordingDeleted = new EventEmitter<void>();
  @Output() discardRecording = new EventEmitter<void>();


  @ViewChild('motivatorDropdown') motivatorDropdown!: ElementRef<HTMLDetailsElement>;
  @ViewChild('confirmModal') confirmModal!: ElementRef;
  @ViewChild('successModal') successModal!: ElementRef;
  @ViewChild('stopModal') stopModal!: ElementRef<HTMLDialogElement>;

  // UI-related properties
  webcamImage: any;
  randomPosterUrl!: string;
  recordingTime = '00:00';
  videoBlob: Blob | null = null;
  availableEffects: Array<'Pinwheel' | 'Bubbles' | 'Music'> = [];
  selectedMode: 'Pinwheel' | 'Bubbles' | 'Music' | null = 'Pinwheel';
  motivatorColorClass = 'bg-blue-300 text-blue-900';
  selectedMotivator = '🎡 Pinwheel';
  isPreparingForEditing = false;
  preparingProgress = 0;
  isModalOpen = new BehaviorSubject<boolean>(true);
  preparingCompleted = false;

  // Calibration-related properties
  calibrationStep: 'blow' | 'laugh' | 'clap' | 'hello' | null = null;
  calibrationInstructions = '';
  calibrationCountdown = 0;
  calibrationData: number[] = [];

  // Private properties
  private stream: MediaStream | null = null;
  private routerSubscription!: Subscription;
  private recordingStartTime = 0;
  private pausedTime = 0;
  private audioSubscription: Subscription | null = null;
  private recordingInterval: any;
  private onConfirm: (() => void) | null = null;
  private blowingCardTimer: any;
  private canvasStream: MediaStream | null = null;
  private animationFrameId: number | null = null;
  private lastTimestamp = 0;
  private destroy$ = new Subject<void>();
  private isLoggingTimer = true;
  private hasLoggedTimerDrawing = false;
  private countdownStarted = false;

  // State flags
  isRecording = false;
  webcamStarted = false;
  isPaused = false;
  showSuccessCard = false;
  demoStep = 1;
  demoStepCompleted = false;
  demoLaunched = false;
  moveUp = false;
  showBlowingCard = false;
  showSaveDialog = false;
  isAnimating = false;
  showSaveOptions = false;
  isUploading = false;
  cameraVisible = true;
  showTriggerAbsenceCard = false;
  showKeepBlowingCard = false;

  // Audio and recording related properties
  consecutivePositivePatterns = 0;
  remainingTime = 180;

  maxObservedLevel = 0.6;
  recordingDuration = 0;
  recordingProgress = 0;
  lastFrameAspectRatio = 16 / 9;

  // Signals and Observables
  webcamActive = signal(false);
  uploadSuccess = signal(false);
  audioLevel$ = new BehaviorSubject<number>(0);
  isRecordingStopped = signal(false);
  uploadProgress = signal(0);
  isCalibrating = signal(false);
  previousLevels = signal<number[]>([]);
  threshold$ = new BehaviorSubject<number>(0);
  acceleration$ = new BehaviorSubject<number>(1);

  // Miscellaneous propertiess
  confirmMessage = '';
  lastFrame: string | null = null;
  recordedBlob: Blob | null = null;

  private userService = inject(UserService);
  isAdmin = this.userService.isAdmin;

  // Getters
  getStream(): MediaStream | null {
    return this.stream;
  }

  // Fullscreen video state
  isFullscreenVideo = new BehaviorSubject<boolean>(false);

  @Input() debugMode = false;

  private isClosing = false;
  private resizeObserver: ResizeObserver | null = null;
  private drawDimensions = { drawWidth: 0, drawHeight: 0, xOffset: 0, yOffset: 0 };
  private _isLargeViewport = false;

  private resizeListener: () => void;

  private audioDetectionService = inject(AudioDetectionService);
  private soundProfileService = inject(SoundProfileService);
  private webcamControlService = inject(WebcamControlService);
  private recordingService = inject(RecordingService);
  private recordingTimerService = inject(RecordingTimerService);
  private resizeService = inject(ResizeService);
  private canvasDrawService = inject(CanvasDrawService);

  private document = inject(DOCUMENT);
  private platformId = inject(PLATFORM_ID);

  @ViewChild(ModeOverlayComponent) modeOverlay!: ModeOverlayComponent;

  deviceTypes: readonly DeviceType[] = DEVICE_TYPES;
  selectedDeviceType = signal<DeviceType>('desktop + microphone');
  availableModes: string[] = ['Bubbles', 'Pinwheel', 'Music']; // Add your available modes here

  showStartBlowingCard = false;
  showMaintainBlowingCard = false;

  showDeviceTypeCard = false;
  deviceTypeMessage = '';
  private deviceTypeCardTimeout: any;

  private loginModalService = inject(LoginModalService);

  private router = inject(Router);


  constructor(

    private changeDetectorRef: ChangeDetectorRef,
    private ngZone: NgZone,
    private firestore: Firestore,
    private injector: Injector,
    private elementRef: ElementRef,

    private toastService: ToastService,
    private route: ActivatedRoute,
    private debugLogger: DebugLoggerService,
    private destroyRef: DestroyRef,
    
  ) {
    if (DEBUG_MODE) this.log('AudioDetectionService injected:', this.audioDetectionService);

    this.resizeListener = this.onWindowResize.bind(this);

    // Initialize viewport size
    this.checkViewportSize();

    // Debounce the checkViewportSize method
    this.debouncedCheckViewportSize = debounce(this.checkViewportSize.bind(this), 250);

    if (isPlatformBrowser(this.platformId)) {
      runInInjectionContext(this.injector, () => {
        effect(() => {
          if (this.webcamActive()) {
            this.document.body.classList.add('webcam-active');
          } else {
            this.document.body.classList.remove('webcam-active');
          }
        });
      });
    }

    this.resizeService.resizeComplete.subscribe(() => {
      // Perform any actions needed after resize is complete
      if (DEBUG_MODE) console.log('Resize complete');
      // For example, you might want to redraw something on the canvas here
    });
  }

  get isLargeViewport(): boolean {
    return this._isLargeViewport;
  }

  /**
   * Checks and updates the viewport size.
   */
  private checkViewportSize(): void {
    this._isLargeViewport = window.innerWidth >= 1024; // Tailwind's 'lg' breakpoint
    if (DEBUG_MODE) console.log('Checking viewport size:', this._isLargeViewport ? 'Large' : 'Small');

    if (this.webcamStarted) {
      this.resizeService.adjustWebcamContainerSize(this.webcamContainer.nativeElement, this.webcamStarted);
      this.adjustCanvasSize();
    }
  }

  ngOnInit() {
    this.demoMode = !this.detectionMode;
    this.checkViewportSize();

    this.randomPosterUrl = this.getRandomPoster();

    this.routerSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationStart && this.webcamStarted) {
        this.stopWebcam();
      }
    });

    this.loadAvailableEffects();
    this.loadSoundProfiles();

    this.recordingService.isRecording$.subscribe(isRecording => {
      this.isRecording = isRecording;
    });

    this.recordingService.recordingProgress$.subscribe(progress => {
      this.recordingProgress = progress;
    });

    this.uploadProgress = this.recordingService.getUploadProgress();

    // Subscribe to the recordedBlob$ observable
    this.recordingService.recordedBlob$.subscribe(blob => {
      this.recordedBlob = blob;
    });

    this.route.queryParams.subscribe(params => {
      this.debugMode = params['debug'] === 'true';
    });

    // Add event listener for resize
    window.addEventListener('resize', this.debouncedCheckViewportSize);

    this.resizeListener = this.onWindowResize.bind(this);
    window.addEventListener('resize', this.resizeListener);

    this.resizeService.logDimensionsWebcamOff(
      this.platformId,
      this.webcamContainer?.nativeElement,
      this.isLargeViewport,
      this.detectionMode
    );

    // Reset recording state when component initializes
    this.recordingService.resetRecording();

    this.recordingTimerService.remainingTime$.subscribe(time => {
      if (DEBUG_MODE) console.log('WebcamComponent: Received updated time:', time);
      this.remainingTime = time;
      // Trigger a redraw of the canvas here if needed
    });

    this.deviceTypes = this.webcamControlService.getDeviceTypes();
    this.selectedDeviceType.set(this.webcamControlService.getSelectedDeviceType());

    this.resizeListener = this.onResize.bind(this);
    window.addEventListener('resize', this.resizeListener);

  }

  ngOnDestroy(): void {
    // Stop webcam and reset related states
    this.stopWebcam();
    this.webcamStarted = false;
    this.webcamActive.set(false);
    this.demoLaunched = false;
    this.demoStep = 1;
    this.demoStepCompleted = false;
    this.showBlowingCard = false;
    this.moveUp = false;
    this.resetRecordingState();
    this.remainingTime = 180;
    this.stopAudioDetection();
    this.isAnimating = false;

    // Clean up timers and animations
    if (this.animationFrameId) {
      cancelAnimationFrame(this.animationFrameId);
    }
    this.cancelAnimationFrame();

    // Reset UI states
    this.resetConsecutivePatterns();
    this.showKeepBlowingCard = false;
    this.isFullscreenVideo.next(false);

    // Reset container height when stopping webcam
    if (this.webcamContainer?.nativeElement) {
      this.webcamContainer.nativeElement.style.height = '';
      this.webcamContainer.nativeElement.style.position = '';
      this.webcamContainer.nativeElement.style.top = '';
      this.webcamContainer.nativeElement.style.left = '';
      this.webcamContainer.nativeElement.style.width = '';
      this.webcamContainer.nativeElement.style.height = '';
      this.webcamContainer.nativeElement.style.display = '';
      this.webcamContainer.nativeElement.style.justifyContent = '';
      this.webcamContainer.nativeElement.style.alignItems = '';
    }

    // Clean up DOM elements
    if (this.videoElement?.nativeElement) {
      this.videoElement.nativeElement.srcObject = null;
      this.videoElement.nativeElement.classList.add('blur-md');
      this.videoElement.nativeElement.style.width = '';
      this.videoElement.nativeElement.style.height = '';
      this.videoElement.nativeElement.style.objectFit = '';
    }

    // Clean up subscriptions and observers
    this.destroy$.next();
    this.destroy$.complete();
    this.routerSubscription?.unsubscribe();
    this.audioSubscription?.unsubscribe();

    // Stop media streams
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
    }

    // Clean up services
    this.audioDetectionService.stopListening();

    // Remove event listeners and observers
    this.destroyResizeObserver();
    window.removeEventListener('resize', this.debouncedCheckViewportSize);
    window.removeEventListener('resize', this.resizeListener);

    // Final UI updates
    this.adjustWebcamSize();
    this.changeDetectorRef.detectChanges();

    // Reset recording state when component is destroyed
    this.recordingService.resetRecording();
    this.stopDrawLoop();

    // Ensure we remove the class when the component is destroyed
    if (isPlatformBrowser(this.platformId)) {
      this.document.body.classList.remove('webcam-active');
    }

    if (this.deviceTypeCardTimeout) {
      clearTimeout(this.deviceTypeCardTimeout);
    }
  }

  ngAfterViewInit(): void {
    // Ensure the container is initialized before logging
    this.ngZone.runOutsideAngular(() => {
      setTimeout(() => {
        this.resizeService.adjustWebcamContainerSize(this.webcamContainer.nativeElement, this.webcamStarted);
        this.resizeService.logDimensionsWebcamOff(
          this.platformId,
          this.webcamContainer?.nativeElement,
          this.isLargeViewport,
          this.detectionMode
        );

        // Set up a subscription to start the draw loop after resizing is complete
        this.resizeService.setupComplete$.pipe(take(1)).subscribe(() => {
          this.startDrawLoop();

          // Initialize canvas animation after draw loop starts
          this.animateCanvas();
        });
      }, 0);
    });

    // Other initialization code can go here
  }

  /**
   * Draws the video stream to the canvas.
   */
  private drawToCanvas(): void {
    if (!this.canvasElement || !this.videoElement) return;

    const canvas = this.canvasElement.nativeElement;
    const video = this.videoElement.nativeElement;
    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Delegate drawing to CanvasDrawService
    this.canvasDrawService.drawToCanvas(
      canvas,
      video,
      this.drawDimensions,
      this.detectionMode,
      this.formatTime.bind(this), // Bind the formatTime function
      this.cameraVisible,
      this.remainingTime // Pass the viewport size flag
    );
  }

  /**
   * Animates the canvas by continuously drawing frames.
   */
  private animateCanvas(): void {
    this.drawToCanvas();
    this.animationFrameId = requestAnimationFrame(() => this.animateCanvas());
  }

  /**
   * Starts the draw loop for the canvas.
   */
  private startDrawLoop(): void {
    const animate = () => {
      this.drawToCanvas();
      this.animationFrameId = requestAnimationFrame(animate);
    };
    animate();
  }

  /**
   * Stops the draw loop for the canvas.
   */
  private stopDrawLoop(): void {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }

  /**
   * Initializes the canvas dimensions based on the video.
   */
  private initializeCanvas() {
    if (this.canvasElement && this.videoElement) {
      const canvas = this.canvasElement.nativeElement;
      const video = this.videoElement.nativeElement;
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
    }
  }

  /**
   * Handles window resize events.
   */
  private onWindowResize() {
    this.ngZone.run(() => {
      this.checkViewportSize();
      this.resizeService.adjustWebcamContainerSize(this.webcamContainer.nativeElement, this.webcamStarted);
      this.adjustCanvasSize();
      this.changeDetectorRef.detectChanges();
      this.resizeService.logDimensionsWebcamOn(
        this.platformId,
        this.videoElement?.nativeElement,
        this.canvasElement?.nativeElement,
        this.webcamContainer?.nativeElement,
        this.isLargeViewport,
        this.detectionMode
      );
    });
  }

  /**
   * Destroys the resize observer if it exists.
   */
  private destroyResizeObserver() {
    if (this.resizeObserver) {
      this.resizeObserver.disconnect();
      this.resizeObserver = null;
    }
  }

  /**
   * Loads sound profiles from Firestore.
   */
  private async loadSoundProfiles() {
    try {
      await this.soundProfileService.loadSoundProfiles();
      const currentBlowProfile = this.soundProfileService.getBlowProfile();
      if (DEBUG_MODE) console.log('Current blow profile after loading:', currentBlowProfile);
    } catch (error) {
      console.error('Error loading sound profiles:', error);
    }
  }
  /**
   * Clears all sound profiles.
   */
  async clearSoundProfiles() {
    if (DEBUG_MODE) console.group('WebcamComponent: clearSoundProfiles');
    try {
      await this.soundProfileService.clearSoundProfiles();
      this.toastService.showToast('All sound profiles cleared', 'success');
      if (DEBUG_MODE) console.log('Sound profiles cleared successfully');
    } catch (error) {
      console.error('Error clearing sound profiles:', error);
      this.toastService.showToast('Failed to clear sound profiles', 'error');
      if (DEBUG_MODE) console.error('Failed to clear sound profiles:', error);
    }
    if (DEBUG_MODE) console.groupEnd();
  }

  /**
   * Updates the recording canvas during recording.
   */
  private updateRecordingCanvas(): void {
    if (DEBUG_MODE) console.group('WebcamComponent: updateRecordingCanvas');
    if (this.isRecording && this.recordingCanvas && this.videoElement) {
      const ctx = this.recordingCanvas.getContext('2d');
      const video = this.videoElement.nativeElement;
      if (ctx && video.videoWidth > 0 && video.videoHeight > 0) {
        ctx.drawImage(video, 0, 0, this.recordingCanvas.width, this.recordingCanvas.height);
        if (DEBUG_MODE) console.log('Canvas updated');
      }
      requestAnimationFrame(() => this.updateRecordingCanvas());
    }
    if (DEBUG_MODE) console.groupEnd();
  }

  /**
   * Starts the recording process.
   */
  async startRecording(): Promise<void> {
    if (DEBUG_MODE) console.group('WebcamComponent: startRecording');
    if (!this.canvasElement || !this.stream) {
      console.error('Canvas element or stream not found');
      if (DEBUG_MODE) console.error('Canvas element or stream not found');
      if (DEBUG_MODE) console.groupEnd();
      return;
    }

    const canvas = this.canvasElement.nativeElement;
    const videoStream = canvas.captureStream(30); // 30 FPS, adjust as needed
    if (DEBUG_MODE) console.log('Video stream captured');

    // Get the audio track from the original stream
    const audioTrack = this.stream.getAudioTracks()[0];
    if (!audioTrack) {
      console.error('No audio track found in the original stream');
      if (DEBUG_MODE) console.error('No audio track found in the original stream');
      if (DEBUG_MODE) console.groupEnd();
      return;
    }
    if (DEBUG_MODE) console.log('Audio track found');
    if (DEBUG_MODE) console.groupEnd();

    // Combine video and audio tracks
    const combinedStream = new MediaStream([...videoStream.getVideoTracks(), audioTrack]);

    try {
      this.isRecording = true;
      this.isPreparingForEditing = false;
      this.preparingCompleted = false; // Reset this flag
      this.preparingProgress = 0;
      await this.recordingService.startRecording(combinedStream);
      this.recordingStarted.emit();
      this.recordingTimerService.startTimer(180); // Start the timer with 3 minutes
      // Start the canvas animation loop if it's not already running
      if (!this.animationFrameId) {
        this.animateCanvas();
      }
    } catch (error: unknown) {
      console.error('Error starting recording:', error);
      this.toastService.showToast('Failed to start recording', 'error');
    }
  }

  /**
   * Resets the recording timer.
   */
  resetTimer(): void {
    this.remainingTime = 180; // Reset to 3 minutes (180 seconds)
    this.recordingTimerService.resetTimer();
  }

  /**
   * Stops the recording process.
   */
  async stopRecording(): Promise<void> {
    if (DEBUG_MODE) console.group('WebcamComponent: stopRecording');
    const logs: string[] = [];

    try {
      logs.push('Stopping recording');

      // Stop the actual recording
      const blob = await this.recordingService.stopRecording();
      logs.push('Recording stopped');

      if (!blob) {
        throw new Error('No blob received after stopping recording');
      }
      logs.push(`Blob received: ${blob.size} bytes`);

      this.isRecording = false;
      this.isPaused = false;
      this.isRecordingStopped.set(true);
      this.recordedBlob = blob;

      // Stop the timer
      this.recordingTimerService.stopTimer();

      // Handle any post-recording UI updates
      this.updateUIAfterRecording();

    } catch (error) {
      logs.push(`Error in stopping recording: ${error instanceof Error ? error.message : 'Unknown error'}`);
      console.error('Error in stopRecording:', error);
    } finally {
      // Log debug info
      await this.debugLogger.logDebugInfo('stopRecording', logs);
      if (DEBUG_MODE) console.groupEnd();
    }
  }

  

  /**
   * Updates the UI after recording stops.
   */
  private updateUIAfterRecording(): void {
    // Implement any UI updates needed after recording stops
    // For example:
    this.showSuccessCard = true;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Adjusts the webcam size based on the current layout.
   */
  private adjustWebcamSize(): void {
    const container = this.elementRef.nativeElement.querySelector('.webcam-container');
    const canvas = this.canvasElement.nativeElement;

    if (container && canvas) {
      canvas.width = container.offsetWidth;
      canvas.height = container.offsetHeight;
    }
  }

  /**
   * Stops audio detection services.
   */
  private stopAudioDetection(): void {
    this.audioDetectionService.stopListening();

    if (this.audioSubscription) {
      this.audioSubscription.unsubscribe();
      this.audioSubscription = null;
    }
  }

  /**
   * Stops the webcam stream.
   */
  stopWebcamStream() {
    if (this.stream) {
      this.stream.getTracks().forEach(track => track.stop());
      this.stream = null;
    }
  }

  /**
   * Updates the recording progress.
   */
  private updateRecordingProgress(): void {
    if (this.isRecording && !this.isPaused) {
      const elapsedTime = Date.now() - this.recordingStartTime;
      const progress = (elapsedTime / (180 * 1000)) * 100; // Assuming 180 seconds max recording time
      this.recordingProgress = Math.min(progress, 100);
      requestAnimationFrame(() => this.updateRecordingProgress());
    }
  }

  /**
   * Pauses the recording.
   */
  pauseRecording(): void {
    this.recordingService.pauseRecording();
    this.isPaused = true;
  }

  /**
   * Resumes the recording.
   */
  resumeRecording(): void {
    this.recordingService.resumeRecording();
    this.isPaused = false;
  }

  /**
   * Logs messages in debug mode.
   * @param args Arguments to log.
   */
  private log(...args: any[]): void {
    if (DEBUG_MODE) {
      console.log(...args);
    }
  }

  /**
   * Formats time from seconds to MM:SS.
   * @param seconds Number of seconds.
   * @returns Formatted time string.
   */
  private formatTime(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes.toString().padStart(2, '0')}:${remainingSeconds.toString().padStart(2, '0')}`;
  }

  /**
   * Adds a leading zero to single-digit numbers.
   * @param value The number to pad.
   * @returns Padded string.
   */
  pad(value: number): string {
    return value < 10 ? `0${value}` : `${value}`;
  }

  /**
   * Displays the confirmation modal.
   * @param message The confirmation message.
   * @param onConfirm The callback to execute on confirmation.
   */
  showConfirmModal(message: string, onConfirm: () => void): void {
    this.confirmMessage = message;
    this.onConfirm = onConfirm;
    this.confirmModal.nativeElement.showModal();
  }

  /**
   * Confirms the action in the modal.
   */
  confirmAction(): void {
    this.confirmModal.nativeElement.close();
    if (this.onConfirm) {
      this.onConfirm();
    }
    this.resetRecordingState();
    this.remainingTime = 180;
  }

  /**
   * Cancels the action in the modal.
   */
  cancelAction(): void {
    this.confirmModal.nativeElement.close();
  }

  /**
   * Resets the recording state.
   */
  private resetRecordingState(): void {
    this.isRecording = false;
    this.isPaused = false;
    this.isRecordingStopped.set(false);
    this.recordingProgress = 0;
    this.recordingDuration = 0;
    this.lastFrame = null;
    // Add any other properties that need to be reset
  }

  /**
   * Retrieves the video blob from IndexedDB.
   */
  async retrieveVideoFromIndexedDB(): Promise<Blob | null> {
    const db = await openDB('VideoStorage', 1);
    const video = await db.get('videos', 'recordedVideo');
    return video || null;
  }

  /**
   * Advances to the next demo step.
   */
  nextDemoStep(): void {
    if (this.demoStep === 1) {
      this.demoStep = 2;
      this.showBlowingCard = true;
      setTimeout(() => {
        this.showBlowingCard = false;
        this.changeDetectorRef.detectChanges();
      }, 5000);
    } else {
      this.demoStepCompleted = true;
    }
  }

  /**
   * Requests webcam permissions.
   */
  requestWebcamPermissions() {
    if (isPlatformBrowser(this.platformId) && navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      navigator.mediaDevices.getUserMedia({ video: true })
        .then(stream => {
          const videoElement = document.querySelector('video');
          if (videoElement) {
            videoElement.srcObject = stream;
          }
        })
        .catch(err => {
          console.error('Error accessing webcam: ', err);
        });
    } else {
      console.error('MediaDevices API not supported in this browser.');
    }
  }

  handleImage(event: any) {
    this.webcamImage = event;
  }

  onSave() {
    this.saveVideo.emit();
  }

  onSend() {
    this.sendVideo.emit();
  }

  /**
   * Saves the recording and triggers download.
   */
  saveRecording() {
    if (this.videoBlob) {
      const url = URL.createObjectURL(this.videoBlob);
      const a = document.createElement('a');
      a.style.display = 'none';
      a.href = url;
      a.download = 'recording.webm';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
    }
    this.stopModal.nativeElement.close();
  }

  /**
   * Selects a random poster image.
   * @returns The URL of the selected poster.
   */
  getRandomPoster(): string {
    const posters = ['background-video01.png', 'background-video02.png'];
    return `assets/images/${posters[Math.floor(Math.random() * posters.length)]}`;
  }

  onVideoRecorded(blob: Blob) {
    this.videoBlob = blob;
  }

  resetComponent() {
    // Implement any reset logic if needed
  }

  /**
   * Saves the video and handles post-save actions.
   * @param action The action to perform ('keep', 'discard', 'delete').
   */
  async saveVideoAndReturn(action: 'keep' | 'discard' | 'delete'): Promise<void> {
    if (this.debugMode) console.group('WebcamComponent: saveVideoAndReturn');
    if (action === 'keep') {
      if (this.recordedBlob) {
        if (this.debugMode) console.log('Saving video, blob size:', this.recordedBlob.size);
        this.isUploading = true;
        this.isPreparingForEditing = true;
        this.preparingProgress = 0;
        this.preparingCompleted = false;

        const startTime = Date.now();
        const minPreparationTime = 5000; // 5 seconds in milliseconds

        const progressInterval = setInterval(() => {
          this.preparingProgress += 2; // Slower progress increment
          if (this.debugMode) console.log(`Preparing progress ${this.preparingProgress}%`);
          if (this.preparingProgress >= 100) {
            clearInterval(progressInterval);
          }
        }, 100);

        try {
          const result = await this.recordingService.saveRecording(
            this.userService.user()?.id,
            'webcam',
            this.recordedBlob
          );

          if (this.debugMode) console.log('Save result:', result);

          if (result.success && result.data) {
            // Ensure minimum preparation time
            const elapsedTime = Date.now() - startTime;
            if (elapsedTime < minPreparationTime) {
              await new Promise(resolve => setTimeout(resolve, minPreparationTime - elapsedTime));
            }

            this.toastService.showToast('Video saved successfully!', 'success');
            this.saveVideo.emit({
              downloadURL: result.data.downloadURL,
              filename: result.data.filename
            });
            this.uploadSuccess.set(true);
            this.preparingCompleted = true;
            if (this.debugMode) console.log('Preparation completed.');
          } else {
            this.toastService.showToast('Failed to save video.', 'error');
            if (this.debugMode) console.warn('Save video failed without error.');
          }
        } catch (error) {
          if (this.debugMode) console.error('Error saving video:', error);
          this.toastService.showToast('An error occurred while saving the video.', 'error');
        } finally {
          clearInterval(progressInterval);
          this.isUploading = false;

          // Delay setting isPreparingForEditing to false
          setTimeout(() => {
            this.isPreparingForEditing = false;
            this.changeDetectorRef.detectChanges();
          }, 1000); // 1 second delay after completion
        }
      } else {
        this.toastService.showToast('No video recorded to save.', 'error');
        if (this.debugMode) console.warn('Attempted to save without a recorded blob.');
      }
    } else if (action === 'discard' || action === 'delete') {
      this.discardAndCloseModal();
    }
    if (this.debugMode) console.groupEnd();
  }

  /**
   * Finalizes the video saving process.
   */
  private finalizeSaveVideo(): void {
    if (this.debugMode) console.group('WebcamComponent: finalizeSaveVideo');
    // Additional logic if needed
    if (this.debugMode) console.log('Finalizing video save.');
    this.isPreparingForEditing = false;
    this.preparingProgress = 0;
    if (this.debugMode) console.groupEnd();
  }

  /**
   * Closes the preparing dialog manually.
   */
  private resetWebcamView(): void {
    this.isRecordingStopped.set(false);
    this.recordedBlob = null;
    this.lastFrame = null;
    this.isRecording = false;
    this.isPaused = false;
    this.recordingProgress = 0;
    this.recordingDuration = 0;
    this.remainingTime = 180; // Reset to 3 minutes

    // Ensure the camera is visible
    this.cameraVisible = true;

    // Reset any other relevant properties
    this.showSuccessCard = false;

    // Redraw the canvas
    this.drawToCanvas();

    // Emit an event to notify parent components if necessary
    this.recordingDeleted.emit();
  }

  /**
   * Cancels the save options.
   */
  cancelSaveOptions() {
    this.isRecordingStopped.set(false);
    this.recordedBlob = null;
  }

  /**
   * Closes the modal dialog action.
   */
  closeModalAction(): void {
    if (this.debugMode) console.group('WebcamComponent: closeModalAction');
    if (this.isClosing) {
      if (this.debugMode) console.log('Already closing, ignoring duplicate call');
      if (this.debugMode) console.groupEnd();
      return;
    }

    this.isClosing = true;
    if (this.debugMode) console.log('Close/Delete button clicked');

    this.ensureWebcamAndMicFullyStopped();

    if (this.recordedBlob) {
      const blobIdentifier = `${this.recordedBlob.size}_${new Date().getTime()}`;
      if (this.debugMode) console.log(`Deleting recorded blob. Identifier: ${blobIdentifier}`);

      this.recordingService.clearRecording();

      this.recordedBlob = null;
      localStorage.removeItem('tempRecordedVideo');

      if (this.debugMode) console.log('Recorded blob and local storage cleared');
      this.closeModal.emit(new Event('blobDeleted'));
    } else {
      if (this.debugMode) console.log('No recorded blob to delete');
      this.closeModal.emit(new Event('modalClosed'));
    }

    if (this.debugMode) console.trace('Trace for closeModalAction');
    if (this.debugMode) console.groupEnd();

    if (this.detectionMode) {
      this.resetAndClose();
    } else {
      this.stopWebcam();
    }

    this.isRecordingStopped.set(false);
    this.resetWebcamView();

    this.isClosing = false;
    this.isModalOpen.next(false);
    this.isPreparingForEditing = false;
    this.preparingCompleted = false; // Reset preparing state
    this.preparingProgress = 0;

    if (this.debugMode) console.log('closeModalAction completed, isModalOpen set to false');
  }

  /**
   * Resets and closes the webcam.
   */
  private resetAndClose(): void {
    if (this.isRecording) {
      this.stopRecording();
    }
    this.stopWebcam();
    this.clearRecordedData();
    this.cancelAnimationFrame();
    this.closeModal.emit(new Event('blobDeleted'));
  }

  /**
   * Clears the recorded data.
   */
  private clearRecordedData(): void {
    this.videoBlob = null;
    this.recordedBlob = null;
    this.isRecordingStopped.set(false);
    this.isRecording = false;
  }

  /**
   * Toggles the visibility of the camera.
   */
  toggleCameraVisibility(): void {
    this.cameraVisible = !this.cameraVisible;
    if (this.videoElement && this.videoElement.nativeElement) {
      if (this.cameraVisible) {
        this.startCamera();
      } else {
        this.stopCamera();
      }
    }
    this.drawToCanvas();
  }

  /**
   * Starts the camera.
   */
  private startCamera(): void {
    if (this.stream) {
      const videoTrack = this.stream.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = true;
      }
      this.videoElement.nativeElement.srcObject = this.stream;
      this.videoElement.nativeElement.style.opacity = '1';
    } else {
      // If stream doesn't exist, reinitialize it
      this.initializeWebcam();
    }
  }

  /**
   * Stops the camera.
   */
  private stopCamera(): void {
    if (this.stream) {
      const videoTrack = this.stream.getVideoTracks()[0];
      if (videoTrack) {
        videoTrack.enabled = false;
      }
      this.videoElement.nativeElement.style.opacity = '0';
    }
    if (DEBUG_MODE) console.log('Camera stopped');
  }

  /**
   * Initializes the webcam.
   */
  private initializeWebcam(): void {
    navigator.mediaDevices.getUserMedia({ video: true, audio: true })
      .then(stream => {
        this.stream = stream;
        this.videoElement.nativeElement.srcObject = stream;
        this.videoElement.nativeElement.style.opacity = '1';
        if (DEBUG_MODE) console.log('Webcam initialized');
      })
      .catch(error => {
        console.error('Error accessing media devices.', error);
        if (DEBUG_MODE) console.error('Failed to initialize webcam:', error);
      });
  }

  /**
   * Loads available effects from Firestore.
   */
  private async loadAvailableEffects() {
    const configDoc = doc(this.firestore, 'config', 'modalEffects');
    try {
      const docSnapshot = await getDoc(configDoc);
      if (docSnapshot.exists()) {
        const data = docSnapshot.data();
        this.availableEffects = Object.entries(data)
          .filter(([_, value]) => value === true)
          .map(([key, _]) => this.capitalizeFirstLetter(key) as 'Bubbles' | 'Pinwheel' | 'Music');
        if (DEBUG_MODE) console.log('Available effects loaded:', this.availableEffects);
      }
    } catch (err) {
      console.error('Error loading available effects:', err);
      if (DEBUG_MODE) console.error('Failed to load available effects:', err);
    }
  }

  /**
   * Capitalizes the first letter of a string.
   * @param string The input string.
   * @returns The capitalized string.
   */
  private capitalizeFirstLetter(string: string): string {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  /**
   * Retrieves the emoji based on the selected effect.
   * @param effect The selected effect.
   * @returns The corresponding emoji.
   */
  getEffectEmoji(effect: string): string {
    switch (effect.toLowerCase()) {
      case 'bubbles':
        return '🫧';
      case 'pinwheel':
        return '🎡';
      case 'music':
        return '🎵';
      default:
        return '';
    }
  }

  /**
   * Gets the previous audio level.
   * @param index The index of the level.
   * @returns The audio level.
   */
  getPreviousLevel(index: number): number {
    return this.previousLevels()[index] || 0;
  }

  /**
   * Handles mode selection.
   * @param mode The selected mode.
   */
  onModeSelected(mode: 'Bubbles' | 'Pinwheel' | 'Music') {
    this.isAnimating = true;
    this.modeSelected.emit(mode);
    this.selectedMode = mode;
  }

  /**
   * Constructs the selected motivator with its emoji.
   * @returns The formatted motivator string.
   */
  getSelectedMotivatorWithEmoji(): string {
    return `${this.getEffectEmoji(this.selectedMotivator)} ${this.selectedMotivator}`;
  }

  /**
 * Selects a mode and closes the dropdown.
 * @param mode The mode to select.
 */
  selectMode(mode: 'Bubbles' | 'Pinwheel' | 'Music') {
    this.selectedMode = mode;
    this.selectedMotivator = mode;
    this.closeMotivatorDropdown();
  }

  /**
   * Closes the motivator dropdown.
   */
  closeMotivatorDropdown() {
    if (this.motivatorDropdown && this.motivatorDropdown.nativeElement) {
      this.motivatorDropdown.nativeElement.open = false;
    }
  }

  /**
   * Resets the count of consecutive positive patterns.

/**
 * Resets the count of consecutive positive patterns.
 */
  private resetConsecutivePatterns() {
    this.consecutivePositivePatterns = 0;
  }

  /**
   * Mutes the audio output of the video element.
   */
  private muteAudioOutput(): void {
    if (this.videoElement && this.videoElement.nativeElement) {
      this.videoElement.nativeElement.muted = true;
    }
  }

  /**
   * Cancels the animation frame.
   */
  private cancelAnimationFrame(): void {
    if (this.animationFrameId !== null) {
      cancelAnimationFrame(this.animationFrameId);
      this.animationFrameId = null;
    }
  }

  /**
   * Initializes the component for recording.
   */
  async initializeForRecording(): Promise<void> {
    console.log('WebcamComponent: initializeForRecording called');

    this.isRecording = false;
    this.isPaused = false;
    this.isRecordingStopped.set(false);
    this.recordedBlob = null;
    this.lastFrame = null;
    this.recordingProgress = 0;
    this.recordingDuration = 0;
    this.remainingTime = 180; // Reset to 3 minutes
    this.isLoggingTimer = true; // Enable timer logging
    this.hasLoggedTimerDrawing = false; // Reset this flag

    // Ensure the camera is visible
    this.cameraVisible = true;

    // Reset any other relevant properties
    this.showSuccessCard = false;

    // Activate the camera and start drawing to canvas
    await this.startWebcam();
    // Force a redraw of the canvas
    this.drawToCanvas();

    // Add a small delay to ensure the canvas is ready
    await new Promise(resolve => setTimeout(resolve, 100));

    // Ensure the canvas is properly initialized
    this.initializeCanvas();

    // Check canvas visibility and log its state
    this.checkCanvasVisibility();

    console.log('WebcamComponent: Canvas dimensions:', this.canvasElement.nativeElement.width, 'x', this.canvasElement.nativeElement.height);
    console.log('WebcamComponent: Video dimensions:', this.videoElement.nativeElement.videoWidth, 'x', this.videoElement.nativeElement.videoHeight);

    // Initialize the recording canvas
    this.recordingCanvas = document.createElement('canvas');
    const videoElement = this.videoElement.nativeElement;
    const aspectRatio = videoElement.videoWidth / videoElement.videoHeight;
    this.recordingCanvas.width = 1280; // Set to desired width
    this.recordingCanvas.height = Math.round(1280 / aspectRatio);

    console.log('WebcamComponent: Recording canvas created:', this.recordingCanvas ? 'Yes' : 'No');

    if (this.canvasElement && this.videoElement) {
      console.log('WebcamComponent: Canvas dimensions:', this.canvasElement.nativeElement.width, 'x', this.canvasElement.nativeElement.height);
      console.log('WebcamComponent: Video dimensions:', this.videoElement.nativeElement.videoWidth, 'x', this.videoElement.nativeElement.videoHeight);

      // Initialize the recording canvas
      this.recordingCanvas = document.createElement('canvas');
      // We'll set the dimensions in startActualRecording when we're sure the video is ready

      console.log('WebcamComponent: Recording canvas created:', this.recordingCanvas ? 'Yes' : 'No');
    } else {
      console.error('WebcamComponent: Canvas or video element is not initialized');
    }
  }

  /**
   * Checks the visibility of the canvas and logs its state.
   */
  private checkCanvasVisibility() {
    if (this.canvasElement && this.canvasElement.nativeElement) {
      const canvas = this.canvasElement.nativeElement;
      const isVisible = canvas.offsetParent !== null;
      console.log('WebcamComponent: Canvas visibility:', isVisible);
      console.log('WebcamComponent: Canvas dimensions:', canvas.width, 'x', canvas.height);
      console.log('WebcamComponent: Canvas offset dimensions:', canvas.offsetWidth, 'x', canvas.offsetHeight);
      console.log('WebcamComponent: Canvas style:', canvas.style.cssText);
      console.log('WebcamComponent: Canvas parent style:', canvas.parentElement?.style.cssText);
    } else {
      console.log('WebcamComponent: Canvas element not found');
    }
  }

  /**
   * Stops the webcam and resets states.
   */
  stopWebcam(): void {
    console.log('WebcamComponent: Stopping webcam and microphone');
    this.ensureWebcamAndMicFullyStopped();
    this.webcamStarted = false;
    this.webcamActive.set(false);
    this.demoLaunched = false;
    this.demoStep = 1;
    this.demoStepCompleted = false;
    this.showBlowingCard = false;
    this.moveUp = false;
    this.resetRecordingState();
    this.remainingTime = 180;
    this.stopAudioDetection();
    this.isAnimating = false;

    this.cancelAnimationFrame();
    this.adjustWebcamSize();
    this.changeDetectorRef.detectChanges();

    this.resetConsecutivePatterns();
    this.showKeepBlowingCard = false;

    // Reset fullscreen mode
    this.isFullscreenVideo.next(false);

    // Reset container height when stopping webcam
    if (this.webcamContainer && this.webcamContainer.nativeElement) {
      this.webcamContainer.nativeElement.style.height = '';
      this.webcamContainer.nativeElement.style.position = '';
      this.webcamContainer.nativeElement.style.top = '';
      this.webcamContainer.nativeElement.style.left = '';
      this.webcamContainer.nativeElement.style.width = '';
      this.webcamContainer.nativeElement.style.height = '';
      this.webcamContainer.nativeElement.style.display = '';
      this.webcamContainer.nativeElement.style.justifyContent = '';
      this.webcamContainer.nativeElement.style.alignItems = '';
    }

    if (this.videoElement && this.videoElement.nativeElement) {
      this.videoElement.nativeElement.srcObject = null;
      // Only add blur class when webcam is stopped
      if (!this.webcamStarted) {
        this.videoElement.nativeElement.classList.add('blur-md');
      }
      this.videoElement.nativeElement.style.width = '';
      this.videoElement.nativeElement.style.height = '';
      this.videoElement.nativeElement.style.objectFit = '';
    }

    console.log('WebcamComponent: Webcam and microphone stopped');
  }

  /**
   * Closes the trigger absence card.
   */
  closeTriggerAbsenceCard(): void {
    this.showTriggerAbsenceCard = false;
    this.changeDetectorRef.detectChanges();
  }

  /**
   * Starts drawing on the canvas.
   */
  private startDrawingToCanvas(): void {
    const drawFunction = () => {
      this.canvasDrawService.drawToCanvas(
        this.canvasElement.nativeElement,
        this.videoElement.nativeElement,
        this.drawDimensions,
        this.detectionMode,
        this.formatTime.bind(this), // Ensure correct 'this' context
        this.cameraVisible,
        this.remainingTime
      );
    };
    this.canvasDrawService.startDrawLoop(drawFunction);
  }

  /**
   * Starts the webcam process.
   */
  async startWebcam(): Promise<void> {
    if (DEBUG_MODE) console.group('WebcamComponent: startWebcam');
    try {
      this.stream = await this.webcamControlService.startWebcam();
      await this.handleStreamSuccess(this.stream);
      if (DEBUG_MODE) console.log('Webcam started successfully');
      this.webcamStarted = true;
      this.initializeCanvas();

      this.webcamStarted = true;
      this.changeDetectorRef.detectChanges();
      await this.startAudioDetection(this.stream);

      this.adjustSizes();
      this.startDrawingToCanvas();

      // Force layout recalculation
      setTimeout(() => {
        this.forceLayoutRecalculation();
      }, 100);

      // Show cards only if webcam is started and in demo mode
      if (this.demoMode && this.webcamStarted) {
        this.showStartBlowingCard = true;
        setTimeout(() => {
          this.showStartBlowingCard = false;
          this.changeDetectorRef.detectChanges();

          // Show "During consultation" card after 5 seconds
          setTimeout(() => {
            this.showMaintainBlowingCard = true;
            this.changeDetectorRef.detectChanges();
          }, 5000);
        }, 3000);
      }

    } catch (error) {
      if (DEBUG_MODE) console.error('Error in startWebcam:', error);
    } finally {
      if (DEBUG_MODE) console.groupEnd();
    }
  }

  /**
   * Handles the successful initialization of the media stream.
   * @param stream The media stream.
   */
  private async handleStreamSuccess(stream: MediaStream): Promise<void> {
    this.stream = stream;
    if (this.videoElement && this.videoElement.nativeElement) {
      if (DEBUG_MODE) {
        console.log('Before setting srcObject:', this.getElementDimensions(this.videoElement.nativeElement));
      }
      this.videoElement.nativeElement.srcObject = stream;
      if (DEBUG_MODE) {
        console.log('After setting srcObject:', this.getElementDimensions(this.videoElement.nativeElement));
      }

      await this.videoElement.nativeElement.play();
      if (DEBUG_MODE) {
        console.log('After play():', this.getElementDimensions(this.videoElement.nativeElement));
      }

      // Wait for the video to have its dimensions
      await new Promise<void>((resolve) => {
        const checkDimensions = () => {
          if (this.videoElement.nativeElement.videoWidth > 0) {
            if (DEBUG_MODE) {
              console.log('Video dimensions available:', this.getElementDimensions(this.videoElement.nativeElement));
            }
            resolve();
          } else {
            requestAnimationFrame(checkDimensions);
          }
        };
        checkDimensions();
      });

      this.webcamStarted = true;
      this.webcamActive.set(true);
      this.webcamActiveChange.emit(true);
      this.recordingStarted.emit();
      this.showSuccessCard = false;

      this.isAnimating = true;
      if (this.demoMode) {
        this.demoLaunched = true;
      }

      try {
        await this.loadSoundProfiles();
        await this.startAudioDetection(stream);
      } catch (error) {
        console.error('Error initializing audio detection:', error);
      }

      this.muteAudioOutput();

      // Ensure the video element is visible before adjusting sizes
      this.videoElement.nativeElement.style.display = 'block';

      // Force a reflow to ensure the video element is rendered
      this.videoElement.nativeElement.offsetHeight;

      this.adjustSizes();
      if (DEBUG_MODE) {
        console.log('After adjustSizes:', this.getElementDimensions(this.videoElement.nativeElement));
      }

      this.changeDetectorRef.detectChanges();
      if (DEBUG_MODE) {
        console.log('After detectChanges:', this.getElementDimensions(this.videoElement.nativeElement));
      }

      // Start drawing to canvas immediately
      this.drawToCanvas();
      // Log dimensions after webcam has started
      this.logDimensions();

      // Start audio detection after stream is set up
      await this.startAudioDetection(stream);

      // Log dimensions again after a short delay
      if (DEBUG_MODE) {
        setTimeout(() => this.logDimensions(), 1000);
      }
    }
  }

  /**
   * Retrieves the dimensions of an HTML element.
   * @param element The HTML element.
   * @returns An object containing the dimensions.
   */
  private getElementDimensions(element: HTMLElement) {
    return {
      offsetWidth: element.offsetWidth,
      offsetHeight: element.offsetHeight,
      clientWidth: element.clientWidth,
      clientHeight: element.clientHeight,
      style: {
        width: element.style.width,
        height: element.style.height
      }
    };
  }

  /**
   * Adjusts the sizes of the webcam elements.
   */
  private adjustSizes(): void {
    if (DEBUG_MODE) console.group('WebcamComponent: adjustSizes');
    if (this.webcamContainer && this.videoElement && this.canvasElement) {
      const containerElement = this.webcamContainer.nativeElement;
      const videoElement = this.videoElement.nativeElement;
      const canvasElement = this.canvasElement.nativeElement;

      if (DEBUG_MODE) console.log('Adjusting container size');
      this.resizeService.adjustWebcamContainerSize(containerElement, this.webcamStarted);

      if (DEBUG_MODE) console.log('Adjusting video size');
      this.resizeService.adjustVideoSize(videoElement, containerElement);

      if (DEBUG_MODE) console.log('Adjusting canvas size');
      const { drawWidth, drawHeight, xOffset, yOffset } = this.resizeService.adjustCanvasSize(
        canvasElement,
        videoElement,
        containerElement
      );

      if (DEBUG_MODE) console.log('Updating drawDimensions', { drawWidth, drawHeight, xOffset, yOffset });
      this.drawDimensions = { drawWidth, drawHeight, xOffset, yOffset };

      const isLargeViewport = this.isLargeViewport;
      if (DEBUG_MODE) console.log('Viewport size:', isLargeViewport ? 'Large' : 'Small');

      if (DEBUG_MODE) console.log('Drawing to canvas');
      this.canvasDrawService.drawToCanvas(
        canvasElement,
        videoElement,
        this.drawDimensions,
        this.detectionMode,
        this.formatTime.bind(this),
        this.cameraVisible,
        this.remainingTime
      );

      if (DEBUG_MODE) console.log('Triggering change detection');
      this.changeDetectorRef.detectChanges();

      if (DEBUG_MODE) {
        console.log('Final container dimensions:', containerElement.clientWidth, 'x', containerElement.clientHeight);
        console.log('Final video dimensions:', videoElement.clientWidth, 'x', videoElement.clientHeight);
        console.log('Canvas dimensions set to:', canvasElement.width, 'x', canvasElement.height);
      }

      if (DEBUG_MODE) console.log('Setting setup as complete');
      this.resizeService.setSetupComplete(true);
    } else {
      if (DEBUG_MODE) console.log('Some elements are not available for resizing');
    }
    if (DEBUG_MODE) console.groupEnd();
  
  }

  /**
   * Logs the current dimensions of webcam elements.
   */
  private logDimensions(): void {
    setTimeout(() => {
      this.resizeService.logDimensionsWebcamOn(
        this.platformId,
        this.videoElement?.nativeElement,
        this.canvasElement?.nativeElement,
        this.webcamContainer?.nativeElement,
        this.isLargeViewport,
        this.detectionMode
      );
    }, 0);
  }

  /**
   * Starts audio detection.
   * @param stream The media stream.
   */
  private async startAudioDetection(stream: MediaStream): Promise<void> {
    if (DEBUG_MODE) console.group('WebcamComponent: startAudioDetection');
    try {
      if (DEBUG_MODE) console.log('Initiating audio detection with stream:', stream);
      await this.audioDetectionService.initAudio(stream);
      this.audioDetectionService.startListening();

      await this.loadSoundProfiles();

      // Set up comparison logic
      this.setupAudioComparison();

      if (DEBUG_MODE) console.log('Audio detection started successfully');
    } catch (error) {
      console.error('Failed to start audio detection:', error);
    } finally {
      if (DEBUG_MODE) console.groupEnd();
    }
  }

  private setupAudioComparison() {
    this.audioDetectionService.matchedProfile$.subscribe(matchedProfile => {
      if (matchedProfile && DEBUG_MODE) {
        console.log('Matched profile:', matchedProfile.type);
        // Trigger appropriate action based on matched profile
      }
    });
  }



  /**
   * Adjusts the canvas size.
   */
  private adjustCanvasSize(): void {
    if (this.videoElement?.nativeElement && this.canvasElement?.nativeElement && this.webcamContainer?.nativeElement) {
      const { drawWidth, drawHeight, xOffset, yOffset } = this.resizeService.adjustCanvasSize(
        this.canvasElement.nativeElement,
        this.videoElement.nativeElement,
        this.webcamContainer.nativeElement

      );

      // Update drawDimensions and trigger change detection
      this.drawDimensions = { drawWidth, drawHeight, xOffset, yOffset };
      this.changeDetectorRef.detectChanges();
    }
  }

  /**
   * Discards and closes the modal.
   */
  discardAndCloseModal(): void {
    if (this.debugMode) console.group('WebcamComponent: discardAndCloseModal');
    this.resetRecording();
    this.discardRecording.emit();
    this.closeModal.emit(new Event('discarded'));
    if (this.debugMode) console.groupEnd();
  }

  /**
   * Resets the recording state.
   */
  private resetRecording(): void {
    this.isRecording = false;
    this.isPaused = false;
    this.isRecordingStopped.set(false);
    this.recordedBlob = null;
    this.recordingService.resetRecording();
    this.recordingTimerService.resetTimer();
  }

  /**
   * Removes the blur class from the video element.
   */
  private removeBlurClass(): void {
    if (this.videoElement && this.videoElement.nativeElement) {
      this.videoElement.nativeElement.classList.remove('blur-md');
    }
  }

  ngDoCheck() {
    if (this.webcamStarted && this.videoElement && this.videoElement.nativeElement) {
      // Ensure blur class is removed when webcam is on
      this.removeBlurClass();
    }
  }

  /**
   * Ensures the webcam and microphone are fully stopped along with modal emission.
   */
  private ensureWebcamAndMicFullyStopped(): void {
    if (this.debugMode) console.group('WebcamComponent: ensureWebcamAndMicFullyStopped');

    if (this.debugMode) console.log('Stopping all media tracks.');
    // Stop all tracks in the stream
    if (this.stream) {
      this.stream.getTracks().forEach(track => {
        track.stop();
        if (this.debugMode) console.log(`Stopped track: ${track.kind}`);
      });
      this.stream = null;
    } else {
      if (this.debugMode) console.log('No active stream to stop.');
    }

    // Stop any ongoing recording
    this.recordingService.stopRecording();
    if (this.debugMode) console.log('Recording service stopped.');

    // Reset webcam state
    this.webcamStarted = false;
    this.webcamActive.set(false);
    this.webcamActiveChange.emit(false);
    if (this.debugMode) console.log('Webcam state reset.');

    // Clear video source
    if (this.videoElement && this.videoElement.nativeElement) {
      this.videoElement.nativeElement.srcObject = null;
      if (this.debugMode) console.log('Video source cleared.');
    }

    // Stop audio detection
    this.stopAudioDetection();
    if (this.debugMode) console.log('Audio detection stopped.');

    // Reset other related states
    this.isRecording = false;
    this.isPaused = false;
    this.demoLaunched = false;
    this.isAnimating = false;
    this.preparingCompleted = false;

    // Emit event to notify parent components
    this.closeModal.emit(new Event('webcamAndMicFullyStopped'));
    if (this.debugMode) console.log('Emitted webcamAndMicFullyStopped event.');

    if (this.debugMode) console.groupEnd();
  }

  /**
   * Forces the webcam to stop.
   */
  public forceStopWebcam(): void {
    this.ensureWebcamAndMicFullyStopped();
  }

  onModeChange(mode: 'Pinwheel' | 'Bubbles' | 'Music'): void {
    this.selectedMode = mode;
    // Add any additional logic for mode change
  }

  onDeviceTypeChange(deviceType: DeviceType): void {
    this.selectedDeviceType.set(deviceType);
    this.webcamControlService.setSelectedDeviceType(deviceType);

    // Load blow profiles for the selected device type
    this.audioDetectionService.loadBlowProfilesForDeviceType(deviceType);

    // Show the device type card
    this.deviceTypeMessage = `Audio detection now optimised for ${deviceType}`;
    this.showDeviceTypeCard = true;

    // Clear any existing timeout
    if (this.deviceTypeCardTimeout) {
      clearTimeout(this.deviceTypeCardTimeout);
    }

    // Set a new timeout to hide the card after 3 seconds
    this.deviceTypeCardTimeout = setTimeout(() => {
      this.showDeviceTypeCard = false;
      this.changeDetectorRef.detectChanges();
    }, 3000);
  }

  closeDeviceTypeCard(): void {
    this.showDeviceTypeCard = false;
    if (this.deviceTypeCardTimeout) {
      clearTimeout(this.deviceTypeCardTimeout);
    }
  }

  openRegisterModal() {
    console.log('Attempting to open register modal');
    if (this.debugMode) {
      console.log('Debug mode: Simulating opening register modal');
    } else {
      console.log('Opening register modal');
      this.loginModalService.openLoginModal('register');
    }
  }

  // Add this method to close the "During consultation" card
  closeMaintainBlowingCard() {
    this.showMaintainBlowingCard = false;
    this.changeDetectorRef.detectChanges();
  }

  private forceLayoutRecalculation(): void {
    if (this.webcamContainer) {
      const element = this.webcamContainer.nativeElement;
      element.style.display = 'none';
      void element.offsetHeight; // Trigger a reflow
      element.style.display = '';
    }
  }

  private onResize() {
    this.adjustSizes();
    this.forceLayoutRecalculation();
  }

}
